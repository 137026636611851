import { saveToast } from "functions/toast";
import { saveState } from "functions/useLocalStorage";
import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export const MyParty = ({ HeroItem, myPartyHeroList, setMyPartyHeroList }) => {
  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const newItems = Array.from(myPartyHeroList);
    const [movedItem] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, movedItem);
    setMyPartyHeroList(newItems);
    saveState({ target: "myPartyHeroesState", value: newItems });
    saveToast();
  };

  return (
    <div id="MyParty">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="dragDropBox themeBackgroundColor"
            >
              {myPartyHeroList.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={String(item.id)}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <HeroItem
                        key={item.id}
                        character={item.character}
                        heroCollectionID={item.heroCollectionID}
                        partyID={item.id}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
