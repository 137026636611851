import { create } from "zustand";

export const pageState = create((set) => ({
  theme: "light",
  setTheme(value) {
    set((prevState) => ({ ...prevState, theme: value }));
  },
  toggleTheme() {
    set((prevState) => ({
      ...prevState,
      theme: prevState.theme === "light" ? "dark" : "light",
    }));
  },
}));

export const authState = create((set) => ({
  auth: {},
  setAuth(value) {
    set((prevState) => ({ ...prevState, auth: value }));
  },
  accessToken: null,
  setAccessToken(value) {
    set((prevState) => ({ ...prevState, accessToken: value }));
  },
  socket: null,
  setSocket(value) {
    set((prevState) => ({ ...prevState, socket: value }));
  },
  authSocket: null,
  setAuthSocket(value) {
    set((prevState) => ({ ...prevState, authSocket: value }));
  },
}));
