import AWS, { S3 } from "aws-sdk";
import Swal from "sweetalert2";
import API from "functions/API";
import dayjs from "dayjs";
import axios from "axios";
import {
  AWS_ACCESS_KEY,
  AWS_S3_BUCKET,
  AWS_S3_REGION,
  AWS_SECRET_KEY,
  AWS_URL,
} from "assets/configs/constants";

AWS.config.update({
  accessKeyId: AWS_ACCESS_KEY,
  secretAccessKey: AWS_SECRET_KEY,
});

export const myBucket = new AWS.S3({
  params: { Bucket: AWS_S3_BUCKET },
  region: AWS_S3_REGION,
});

const uploadWithProgress = (
  params,
  progressBarElement,
  progressContextElement
) => {
  const options = {
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      if (progressBarElement) {
        progressBarElement.style.width = `${percentCompleted}%`;
      }
      if (progressContextElement) {
        progressContextElement.textContent = `${percentCompleted}%`;
      }
    },
  };
  return axios.put(
    `https://${params.Bucket}.s3.amazonaws.com/${params.Key}`,
    params.Body,
    options
  );
};

const uploadWithoutProgress = (params) => {
  return axios.put(
    `https://${params.Bucket}.s3.amazonaws.com/${params.Key}`,
    params.Body
  );
};

export const uploadFile = async ({ file, key, withProgressBar }) => {
  const params = {
    Body: file,
    Bucket: AWS_S3_BUCKET,
    Key: key,
  };

  try {
    let result;
    if (withProgressBar) {
      const progressBarElement = document.getElementById(
        `${file.name}_progressBar`
      );
      const progressContextElement = document.getElementById(
        `${file.name}_progressContext`
      );
      result = await uploadWithProgress(
        params,
        progressBarElement,
        progressContextElement
      );
    } else {
      result = await uploadWithoutProgress(params);
    }
    console.log("Upload Result:", result);
    return result;
  } catch (error) {
    console.error("Upload Error:", error);
    throw new Error(error);
  }
};

export const uploadFiles = async ({ files, key, withProgressBar }) => {
  try {
    const results = await Promise.all(
      [...files].map((fileItem) => {
        if (fileItem) {
          const fileKey = `${key}/${dayjs().format("YYYYMMDDHHmmss")}_${
            fileItem.name
          }`;
          return uploadFile({
            file: new File([fileItem], fileItem.name, {
              type: fileItem.type,
              size: fileItem.size,
              lastModified: fileItem.lastModified,
            }),
            key: fileKey,
            withProgressBar,
          });
        }
      })
    );
    console.log("AWS Result:", results);
    return results;
  } catch (error) {
    console.error("AWS Error:", error);
    throw new Error(error);
  }
};
