import { Route, Routes, useNavigate } from "react-router-dom";
import { Header } from "./components/header/Header";
import { Footer } from "./components/footer/Footer";
import { BossTimerSection } from "components/section/bossTimer/BossTimer";
import { Applications } from "components/section/applications/Applications";
import { TacticArticles } from "components/section/tacticArticles/TacticArticles";
import dayjs from "dayjs";
// import localizedFormat from "dayjs/plugin/localizedFormat";
// import customParseFormat from "dayjs/plugin/customParseFormat";
// import updateLocale from "dayjs/plugin/updateLocale";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import "assets/styles/CommonStyle.css";
import "assets/styles/SectionStyle.css";
import "assets/fonts/Fonts.css";
import { SummonCharm } from "components/section/summonCharm/SummonCharm";
import { Login } from "components/section/login/Login";
import verifyToken from "functions/auth/verifyToken";
import { useCookies } from "react-cookie";
import { authState } from "components/common/Zustand";
import refreshAccessToken from "functions/auth/refreshAccessToken";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import API from "functions/API";
import { ToastContainer } from "react-toastify";

function App() {
  const [cookies, setCookie, removeCookie] = useCookies([
    "refreshToken",
    "userID",
  ]);
  const { accessToken, setAuth, setAccessToken, setRefreshToken } = authState();
  const [isAccessTokenVerifyed, setIsAccessTokenVerifyed] = useState(false);
  const [visitors, setVisitors] = useState(0);
  const navigate = useNavigate();

  // Initialize
  dayjs.extend(duration);
  dayjs.extend(utc);
  dayjs.locale("ko");

  // 엑세스 토큰 트리거 생성
  useEffect(() => {
    console.log("APP useEffect");
    console.log("COOKIES : ", cookies);
    console.log("ACCESSTOKEN : ", accessToken);
    console.log("ACCESSTOKEN : ", typeof accessToken);
    verifyToken(accessToken)
      .then((payload) => {
        console.log("1. Access Token Success : ", payload);
        console.log(new Date(payload.exp * 1000));
        setAuth(payload.claims);
        setIsAccessTokenVerifyed(!isAccessTokenVerifyed);
      })
      .catch((error) => {
        console.log("1. Access Token Fail: ", error);
        if (cookies.refreshToken)
          verifyToken(cookies.refreshToken).then((payload) => {
            console.log("2. Refresh Token Success : ", payload);
            console.log(new Date(payload.exp * 1000));
            refreshAccessToken({ cookies })
              .then((anotherAccessToken) => {
                console.log(
                  "3. Refresh Access Token Success : ",
                  anotherAccessToken
                );
                setAccessToken(anotherAccessToken);
              })
              .catch((error) => {
                console.log("3. Refresh Access Token Fail :", error);
              });
          });
      });
    return () => console.log("APP return");
  }, [accessToken]);

  useEffect(() => {
    const getVisitors = async () => {
      let res = await API.get(`api/v1/visitors`);
      let data = await res.data;
      setVisitors(data.visitors);
    };
    const postVisitors = async () => {
      let res = await API.post(`api/v1/visitors`);
      let data = await res.data;
      setVisitors(data.visitors);
    };
    postVisitors();
  }, []);

  return (
    <div id="App" className="themeBackgroundColor">
      <Header />
      <div id="MainPage">
        <div className="wrapper">
          <Routes>
            <Route path="/" element={<BossTimerSection />} />
            <Route path="/BossTimer" element={<BossTimerSection />} />
            <Route path="/Applications/*" element={<Applications />} />
            <Route path="/TacticArticles/*" element={<TacticArticles />} />
            <Route path="/SummonCharm" element={<SummonCharm />} />
            <Route path="/Login" element={<Login />} />
          </Routes>
          <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            rtl={false}
            closeOnClick
            draggable
            theme="light"
            transition="Flip"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
