import React, { useState, useEffect, useRef } from "react";
import heroCollectionBaseData from "data/heroCollectionBaseData.json";
import heroCollectionLevelData from "data/heroCollectionLevelData.json";
import levelExpData from "data/levelExpData.json";
import ReactSelect from "react-select";
import { ReactSelectStyles } from "components/common/ReactSelectStyle";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveToast } from "functions/toast";
import { loadState, saveState } from "functions/useLocalStorage";
import promotionPageImg from "assets/images/img_promotion_page.png";
import promotionSquireImg from "assets/images/img_promotion_squire.png";
import promotionVeteranImg from "assets/images/img_promotion_veteran.png";
import { MyParty } from "./MyParty";
import Swal from "sweetalert2";
import { WheelPicker } from "components/common/wheel-picker/WheelPicker";
import dayjs from "dayjs";
import { PiCheckCircleBold, PiUserCircleCheckBold } from "react-icons/pi";

export const HeroCollection = () => {
  const headerBarHeight = 97;
  const maxLevel = 120;
  const selectLevelOptions = Array.from({ length: 100 }, (_, i) => ({
    value: i + 1,
    label: i + 1,
  }));

  const [heroCollectionData, setHeroCollectionData] = useState(
    loadState({ target: "heroCollectionState", value: heroCollectionBaseData })
  );
  const [heroCollectionResult, setHeroCollectionResult] = useState({});

  const [myPartyHeroesList, setMyPartyHeroesList] = useState(
    loadState({
      target: "myPartyHeroesState",
      value: [
        { id: 1, character: { characterID: 0 }, heroCollectionID: 0 },
        { id: 2, character: { characterID: 0 }, heroCollectionID: 0 },
        { id: 3, character: { characterID: 0 }, heroCollectionID: 0 },
      ],
    })
  );

  const [myFiledList, setMyFiledList] = useState(
    loadState({ target: "myFieldsState", value: [] })
  );

  const [myField, setMyField] = useState({});

  const [heroesOptions, setHeroesOptions] = useState([]);

  const scrollRefs = useRef([]);

  // Initial data setup on component mount
  useEffect(() => {
    console.log("heroCollectionData : ", heroCollectionData);
    if (!heroCollectionData || heroCollectionData?.result?.length > 0) {
      console.log("heroCollectionData is not here");
      console.log("or heroCollectionData is empty");
      const initialState = heroCollectionBaseData;
      setHeroCollectionData(initialState);
      saveState({ target: "heroCollectionState", value: initialState });
    } else {
      let compareCollectionData = heroCollectionData.map((item) => {
        return {
          idx: item.idx,
          heroCollectionID: item.heroCollectionID,
          name: item.name,
        };
      });
      let compareCollectionBaseData = heroCollectionBaseData.map((item) => {
        return {
          idx: item.idx,
          heroCollectionID: item.heroCollectionID,
          name: item.name,
        };
      });
      console.log("compareCollectionData : ", compareCollectionData);
      console.log("compareCollectionBaseData : ", compareCollectionBaseData);
      if (compareCollectionData !== compareCollectionBaseData) {
        console.log("TEST SUCCESS");

        let newCollectionData = heroCollectionBaseData.filter(
          (item, i) => item.idx !== compareCollectionData[i]?.idx
        );
        console.log("newCollectionData : ", newCollectionData);
        let completeCollectionData =
          heroCollectionData.concat(newCollectionData);
        console.log(completeCollectionData);
        setHeroCollectionData(completeCollectionData);
      }
    }
    // setHeroesOptions(() => ({
    //   heroCollectionBaseData
    // }))
  }, []);

  // Calculate ColectionInformation
  useEffect(() => {
    console.log("heroCollectionData : ", heroCollectionData);

    if (heroCollectionData.length == heroCollectionBaseData.length) {
      let result = (heroCollectionLevelData ?? []).map(
        (heroCollectionLevelDataItem) => {
          console.log(
            "heroCollectionDataItem : ",
            heroCollectionData[heroCollectionLevelDataItem.heroCollectionID - 1]
          );
          console.log(
            "heroCollectionLevelDataItem : ",
            heroCollectionLevelDataItem
          );
          console.log(
            "heroCollectionBaseDataItem : ",
            heroCollectionBaseData[
              heroCollectionLevelDataItem.heroCollectionID - 1
            ]
          );

          let heroCollectionDataItem =
            heroCollectionData[
              heroCollectionLevelDataItem.heroCollectionID - 1
            ] ??
            heroCollectionBaseData[
              heroCollectionLevelDataItem.heroCollectionID - 1
            ];

          let myCharacters =
            heroCollectionData[heroCollectionLevelDataItem.heroCollectionID - 1]
              .characters;
          let levelCollection = heroCollectionLevelDataItem.levelCollection;
          let goalCollectionLevel =
            heroCollectionData[heroCollectionLevelDataItem.heroCollectionID - 1]
              .goalCollectionLevel;
          console.log("myCharacters : ", myCharacters);

          const findMaxCollectionLevel = ({ levelCollection }) => {
            return levelCollection.reduce((maxLevel, current) => {
              return current.level > maxLevel ? current.level : maxLevel;
            }, 0);
          };
          const myTotalCurrentLevel = ({ characters }) => {
            return characters.reduce((sum, character) => {
              return sum + character.currentLevel;
            }, 0);
          };
          const myTotalGoalLevel = ({ characters }) => {
            return characters.reduce((sum, character) => {
              return sum + character.goalLevel;
            }, 0);
          };
          const myTotalPromotionLetters = ({ characters }) => {
            var promotionBasicPage = 0;
            var promotionRecruitPage = 0;
            var promotionCollectPage = 0;
            var promotionBasicSquire = 0;
            var promotionRecruitSquire = 0;
            var promotionCollectSquire = 0;
            var promotionBasicVeteran = 0;
            var promotionRecruitVeteran = 0;
            var promotionCollectVeteran = 0;
            characters.forEach((characterItem) => {
              switch (characterItem.grade) {
                case "일반":
                  if (
                    characterItem.currentLevel <= 60 &&
                    characterItem.goalLevel > 60
                  ) {
                    promotionBasicPage += 1;
                  }
                  if (
                    characterItem.currentLevel <= 80 &&
                    characterItem.goalLevel > 80
                  ) {
                    promotionBasicSquire += 1;
                  }
                  if (
                    characterItem.currentLevel <= 100 &&
                    characterItem.goalLevel > 100
                  ) {
                    promotionBasicVeteran += 1;
                  }
                  break;
                case "영입":
                  if (
                    characterItem.currentLevel <= 60 &&
                    characterItem.goalLevel > 60
                  ) {
                    promotionRecruitPage += 1;
                  }
                  if (
                    characterItem.currentLevel <= 80 &&
                    characterItem.goalLevel > 80
                  ) {
                    promotionRecruitSquire += 1;
                  }
                  if (
                    characterItem.currentLevel <= 100 &&
                    characterItem.goalLevel > 100
                  ) {
                    promotionRecruitVeteran += 1;
                  }
                  break;
                case "소장":
                  if (
                    characterItem.currentLevel <= 60 &&
                    characterItem.goalLevel > 60
                  ) {
                    promotionCollectPage += 1;
                  }
                  if (
                    characterItem.currentLevel <= 80 &&
                    characterItem.goalLevel > 80
                  ) {
                    promotionCollectSquire += 1;
                  }
                  if (
                    characterItem.currentLevel <= 100 &&
                    characterItem.goalLevel > 100
                  ) {
                    promotionCollectVeteran += 1;
                  }
                  break;
              }
            });
            return {
              promotionBasicPage,
              promotionRecruitPage,
              promotionCollectPage,
              promotionBasicSquire,
              promotionRecruitSquire,
              promotionCollectSquire,
              promotionBasicVeteran,
              promotionRecruitVeteran,
              promotionCollectVeteran,
            };
          };
          console.log("TEST 2 SUCCESS!!");

          return {
            heroCollectionID: heroCollectionLevelDataItem.heroCollectionID,
            maxCollectionLevel: findMaxCollectionLevel({
              levelCollection: heroCollectionLevelDataItem.levelCollection,
            }),
            totalGoalLevel: myTotalGoalLevel({
              characters: myCharacters,
            }),
            totalCurrentLevel: myTotalCurrentLevel({
              characters: myCharacters,
            }),
            totalPromotionLetters: myTotalPromotionLetters({
              characters: myCharacters,
            }),
            levelCollection: levelCollection,
            goalCollectionLevel: goalCollectionLevel,
          };
        }
      );
      console.log("RESULT : ", result);
      setHeroCollectionResult(result);

      console.log("TEST 3 SUCCESS!!");
      if (heroCollectionData != null) {
        let newArray = [];
        heroCollectionData.forEach((heroCollectionItem) => {
          console.log("heroCollectionItem : ", heroCollectionItem);
          console.log("TEST 4 SUCCESS!!");
          heroCollectionItem.characters.forEach((characterItem) => {
            newArray.push({
              key: characterItem.idx,
              label: characterItem.name,
              data: {
                character: characterItem,
                characterID: characterItem.characterID,
                heroCollectionID: heroCollectionItem.heroCollectionID,
              },
            });
          });
        });
        newArray.sort((a, b) => a.label.localeCompare(b.label, "ko-KR"));
        setHeroesOptions(newArray);
      }
    }
  }, [heroCollectionData]);

  const PromotionLetters = ({ target, number }) => {
    let tempArray = [];
    switch (target) {
      case "promotionBasicPage":
        var grade = "일반";
        var promotion = "페이지";
        break;
      case "promotionRecruitPage":
        var grade = "영입";
        var promotion = "페이지";
        break;
      case "promotionCollectPage":
        var grade = "소장";
        var promotion = "페이지";
        break;
      case "promotionBasicSquire":
        var grade = "일반";
        var promotion = "스콰이어";
        break;
      case "promotionRecruitSquire":
        var grade = "영입";
        var promotion = "스콰이어";
        break;
      case "promotionCollectSquire":
        var grade = "소장";
        var promotion = "스콰이어";
        break;
      case "promotionBasicVeteran":
        var grade = "일반";
        var promotion = "베테랑";
        break;
      case "promotionRecruitVeteran":
        var grade = "영입";
        var promotion = "베테랑";
        break;
      case "promotionCollectVeteran":
        var grade = "소장";
        var promotion = "베테랑";
        break;
    }
    const CreateImage = ({ promotion }) => {
      switch (promotion) {
        case "페이지":
          return <img src={promotionPageImg} />;
        case "스콰이어":
          return <img src={promotionSquireImg} />;
        case "베테랑":
          return <img src={promotionVeteranImg} />;
      }
    };
    const CreateDescription = ({ grade }) => {
      switch (grade) {
        case "일반":
          var colorClassName = "fontColorBasic";
          break;
        case "영입":
          var colorClassName = "fontColorRecruit";
          break;
        case "소장":
          var colorClassName = "fontColorCollect";
          break;
      }
      return (
        <div id="PromotionDescription" className={colorClassName}>
          {grade}
        </div>
      );
    };
    return (
      <div className="flexStartCenter">
        <div id="PromotionImgArea">
          <CreateImage promotion={promotion} />
          <CreateDescription grade={grade} />
        </div>
        <div>
          <span className="ml-qhalf">x</span>
          <span className="ml-qhalf">{number ?? 0}</span>
        </div>
      </div>
    );
  };

  const HeroItem = ({ character, heroCollectionID, partyID }) => {
    const updateHeroData = ({
      heroCollectionID,
      characterID,
      target,
      value,
    }) => {
      console.log(heroCollectionID);
      setHeroCollectionData((prevState) => {
        const heroCollectionIndex = prevState.findIndex(
          (collection) => collection.heroCollectionID === heroCollectionID
        );

        if (heroCollectionIndex === -1) {
          console.error("Hero collection not found");
          return prevState;
        }

        const characterIndex = prevState[
          heroCollectionIndex
        ].characters.findIndex(
          (character) => character.characterID === characterID
        );

        if (characterIndex === -1) {
          console.error("Character not found");
          return prevState;
        }

        const updatedState = [...prevState];
        updatedState[heroCollectionIndex] = {
          ...updatedState[heroCollectionIndex],
          characters: updatedState[heroCollectionIndex].characters.map(
            (character, idx) =>
              idx === characterIndex
                ? { ...character, [target]: value }
                : character
          ),
        };

        saveState({ target: "heroCollectionState", value: updatedState }); // Save to localStorage

        // 추가된 부분: myPartyHeroList도 업데이트
        setMyPartyHeroesList((prevList) => {
          let result = prevList.map((item) =>
            item.character.characterID === characterID
              ? { ...item, character: { ...item.character, [target]: value } }
              : item
          );
          saveState({ target: "myPartyHeroesState", value: result });
          return result;
        });
        saveToast();
        return updatedState;
      });
    };

    const selectLevelOptions = Array.from({ length: maxLevel }, (_, i) => ({
      value: i + 1,
      label: i + 1,
    }));

    const expNeedTotal = ({ currentLevel, goalLevel }) => {
      let currentlevelData = levelExpData[`level${currentLevel}`];
      let goalLevelData = levelExpData[`level${goalLevel}`];
      let expNeedTotal = goalLevelData?.expTotal - currentlevelData?.expTotal;
      if (currentLevel > goalLevel) {
        return 0;
      } else return expNeedTotal;
    };

    const timeNeedTotal = ({ expNeedTotal, expGainPerMinute }) => {
      if (expGainPerMinute === 0 || expGainPerMinute == null) {
        return "(경험치 획득 정보 클릭)";
      } else if (expNeedTotal <= 0) {
        return "없음";
      } else {
        let minutesNeed = Math.round(expNeedTotal / expGainPerMinute);
        let result = `${Math.floor(minutesNeed / 60)}시간 ${Math.round(
          minutesNeed % 60
        )}분`;
        return result;
      }
    };

    const CharacterName = ({ character }) => {
      var promotionColor = "";
      var promotionText = "";
      var gradeColor = "";
      if (character?.currentLevel > 100) {
        promotionColor = "fontColorVeteran";
        promotionText = "베테랑";
      } else if (character?.currentLevel > 80) {
        promotionColor = "fontColorCollect";
        promotionText = "스콰이어";
      } else if (character?.currentLevel > 60) {
        promotionColor = "fontColorRecruit";
        promotionText = "페이지";
      } else {
        promotionColor = "";
      }
      switch (character?.grade) {
        case "일반":
          gradeColor = "";
          break;
        case "영입":
          gradeColor = "fontColorRecruit";
          break;
        case "소장":
          gradeColor = "fontColorCollect";
          break;
      }

      return (
        <div className={`textCenter mt-half`}>
          <div className={`${promotionColor} promotion`}>{promotionText}</div>
          <div>{character?.name}</div>
          <div className={`${gradeColor}`}>({character?.grade})</div>
        </div>
      );
    };

    if (character.characterID === 0) {
      return (
        <div id="NoHeroItem" className="themeBorder2Color">
          영웅을 선택해주세요
          <div className="mt-half">
            <ReactSelect
              styles={ReactSelectStyles({ type: "number", width: "10rem" })}
              options={heroesOptions}
              onChange={(e) =>
                handleClickHeroItem({
                  character: e.data.character,
                  heroCollectionID: e.data.heroCollectionID,
                  partyID: partyID,
                })
              }
            />
          </div>
        </div>
      );
    } else
      return (
        <div
          id="HeroItem"
          className="themeItem2Color themeBorder2Color"
          onClick={() => {
            handleClickHeroItem({
              character,
              heroCollectionID: heroCollectionID,
              partyID: partyID,
            });
          }}
        >
          <div id="Information">
            <img
              src={`https://wooinplt-dev.s3.ap-northeast-2.amazonaws.com/gng/img_character_${character.characterID}.png`}
              alt={character?.name}
            />
            <CharacterName character={character} />
          </div>
          <div id="Controls">
            <div id="LevelControl">
              <div className="flexStart">
                <div className="flexCenterAll">현재 레벨 : </div>
                <div onClick={(e) => e.stopPropagation()}>
                  <ReactSelect
                    className="ml-half"
                    styles={ReactSelectStyles({ type: "number" })}
                    options={selectLevelOptions}
                    placeholder={"Lv"}
                    value={selectLevelOptions.find(
                      (option) => option.value === character.currentLevel
                    )}
                    onChange={(e) => {
                      updateHeroData({
                        heroCollectionID: heroCollectionID,
                        characterID: character.characterID,
                        target: "currentLevel",
                        value: e.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="flexStart">
                <div className="flexCenterAll">목표 레벨 : </div>
                <div onClick={(e) => e.stopPropagation()}>
                  <ReactSelect
                    className="ml-half"
                    styles={ReactSelectStyles({ type: "number" })}
                    options={selectLevelOptions}
                    placeholder={"Lv"}
                    value={selectLevelOptions.find(
                      (option) => option.value === character.goalLevel
                    )}
                    onChange={(e) => {
                      updateHeroData({
                        heroCollectionID: heroCollectionID,
                        characterID: character.characterID,
                        target: "goalLevel",
                        value: e.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            {character?.currentLevel >= character?.goalLevel ? (
              <div id="ExpBrief">
                <div id="LevelUpComplete">
                  목표 레벨 달성
                  <div className="flexCenterAll ml-qhalf h-full">
                    <PiCheckCircleBold size={18} />
                  </div>
                </div>
              </div>
            ) : (
              <div id="ExpBrief">
                <div>
                  필요 경험치 :{" "}
                  {expNeedTotal({
                    currentLevel: character?.currentLevel,
                    goalLevel: character?.goalLevel,
                  }).toLocaleString()}
                </div>
                <div>
                  소요 시간:{" "}
                  {timeNeedTotal({
                    expNeedTotal: expNeedTotal({
                      currentLevel: character?.currentLevel,
                      goalLevel: character?.goalLevel,
                    }),
                    expGainPerMinute: myField?.item?.expGainPerMinute,
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      );
  };

  const HeroCollectionItem = ({ heroCollection }) => {
    const HeroCollectionInfromation = ({ heroCollectionResult }) => {
      const RecommandationBadge = () => {
        return <div id="RecommandationBadge">추천</div>;
      };
      const HeroCollectionStats = React.memo(({ levelCollection }) => {
        const goalCollectionLevel = heroCollection?.goalCollectionLevel;

        const handleClick = (level) => {
          setHeroCollectionData((prevState) => {
            const heroCollectionIndex = prevState.findIndex(
              (collection) =>
                collection.heroCollectionID ===
                heroCollectionResult.heroCollectionID
            );

            if (heroCollectionIndex === -1) {
              console.error("Hero collection not found");
              return prevState;
            }

            const updatedState = [...prevState];
            updatedState[heroCollectionIndex] = {
              ...updatedState[heroCollectionIndex],
              goalCollectionLevel: Number(level),
            };

            saveState({ target: "heroCollectionState", value: updatedState }); // Save to localStorage
            saveToast();
            return updatedState;
          });
        };

        const groupedLevels = (levelCollection ?? []).reduce((acc, item) => {
          if (!acc[item.level]) {
            acc[item.level] = [];
          }
          acc[item.level].push(item);
          return acc;
        }, {});

        return (
          <div id="HeroCollectionStats">
            <div className="sectionSubTitle">목표 도감 레벨</div>
            <div className="sectionSubTitleDescription">
              목표 도감 레벨을 클릭하여 설정하고, 각 영웅들의 목표 레벨을
              조정하여 목표 레벨 합계를 이와 동일하게 설정해주세요.
            </div>
            <div className="itemBox mt-1">
              {Object.keys(groupedLevels).map((level) => (
                <div
                  className={`item themeBorder2Color ${
                    goalCollectionLevel && level <= goalCollectionLevel
                      ? "themeHighlightItem"
                      : ""
                  }`}
                  key={level}
                  onClick={() => {
                    console.log(level);
                    handleClick(Number(level));
                  }}
                >
                  <strong>Level: {level}</strong>
                  {groupedLevels[level].map((item, index) => (
                    <div key={index}>
                      {item.stat}: {item.value}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        );
      });

      return (
        <div
          id="HeroCollectionInformation"
          className="themeItem2Color themeBorder2Color"
        >
          <div id="Level">
            <div className="flexStartCenter">
              최대 도감 레벨<div className="ml-qhalf"></div>:
              <div className="ml-qhalf">
                {heroCollectionResult?.maxCollectionLevel}
              </div>
            </div>
            {/* <div className="flexStartCenter">
              공격력 우선 도감 레벨<div className="ml-qhalf"></div>:
              <div className="ml-qhalf">개발중</div>
              <RecommandationBadge />
            </div> */}
            <div className="flexStartCenter">
              현재 레벨 합계<div className="ml-qhalf"></div>:
              <div
                className={`emphasis ml-qhalf ${
                  heroCollectionResult?.totalCurrentLevel >=
                    heroCollectionResult?.totalGoalLevel && "fontColorComplete"
                }`}
              >
                {heroCollectionResult?.totalCurrentLevel}
              </div>
              {heroCollectionResult?.totalGoalLevel -
                heroCollectionResult?.totalCurrentLevel >
                0 && (
                <div className="emphasis ml-qhalf fontColorRed">
                  (-
                  {heroCollectionResult?.totalGoalLevel -
                    heroCollectionResult?.totalCurrentLevel}
                  )
                </div>
              )}
            </div>
            <div className="flexStartCenter">
              <span>목표 레벨 합계</span>
              <div className="ml-qhalf"></div>:
              <div
                className={`emphasis ml-qhalf ${
                  heroCollectionResult?.totalGoalLevel >=
                    heroCollectionResult?.goalCollectionLevel &&
                  "fontColorComplete"
                }`}
              >
                {heroCollectionResult?.totalGoalLevel}
              </div>
              {heroCollectionResult?.goalCollectionLevel -
                heroCollectionResult?.totalGoalLevel >
                0 && (
                <div className="emphasis ml-qhalf fontColorRed">
                  (-
                  {heroCollectionResult?.goalCollectionLevel -
                    heroCollectionResult?.totalGoalLevel}
                  )
                </div>
              )}
            </div>
            <div className="flexStartCenter">
              {/* <span className="fontColorHighlight">목표 도감 레벨</span> */}
              목표 도감 레벨
              <div className="ml-qhalf"></div>:
              <div className="emphasis ml-qhalf transition">
                {heroCollectionResult?.goalCollectionLevel}
              </div>
            </div>
            <div className="flexStartCenter">
              <div id="PromotionLettersPlaceholder">필요 진급서 :</div>
              <div id="PromotionLetters" className="ml-qhalf">
                {Object.entries(
                  heroCollectionResult?.totalPromotionLetters ?? {}
                ).map((promotionLetterEntryItem, i) => {
                  let target = promotionLetterEntryItem[0];
                  let number = promotionLetterEntryItem[1];
                  if (number > 0)
                    return (
                      <PromotionLetters
                        key={i}
                        target={target}
                        number={number}
                      />
                    );
                })}
              </div>
            </div>
          </div>
          <div id="Stats" className="themeBorder2Color">
            <HeroCollectionStats
              levelCollection={heroCollectionResult?.levelCollection}
            />
          </div>
        </div>
      );
    };
    return (
      <div
        id="HeroCollectionItem"
        ref={(el) => (scrollRefs.current[heroCollection.heroCollectionID] = el)}
        className="scrollMargin"
      >
        <div id="HeroCollectionName">{heroCollection?.name}</div>
        <div id="HerocollectionCharacters">
          {(heroCollection?.characters ?? []).map((characterItem) => (
            <HeroItem
              key={characterItem.characterID}
              character={characterItem}
              heroCollectionID={heroCollection?.heroCollectionID}
            />
          ))}
        </div>
        <HeroCollectionInfromation
          heroCollectionResult={
            heroCollectionResult[heroCollection.heroCollectionID - 1]
          }
        />
      </div>
    );
  };

  const handleClickHeroItem = ({ character, heroCollectionID, partyID }) => {
    if (character.characterID === 0) {
      return;
    } else
      setMyPartyHeroesList((prevList) => {
        // newCharacter의 characterID가 이미 있는지 확인
        const existingIndex = prevList.findIndex(
          (item) => item.character.characterID === character.characterID
        );

        if (existingIndex !== -1) {
          // characterID가 이미 존재하는 경우
          const newList = [...prevList];
          newList[existingIndex] = {
            ...newList[existingIndex],
            character: { characterID: 0 },
            heroCollectionID: 0,
          };
          saveState({ target: "myPartyHeroesState", value: newList });
          saveToast();
          return newList;
        }

        // characterID가 0인 첫 번째 요소의 인덱스를 찾습니다.
        var index;
        if (partyID) {
          index = prevList.findIndex((item) => item.id === partyID);
        } else {
          index = prevList.findIndex(
            (item) => item.character.characterID === 0
          );
        }
        console.log("index : ", index);

        // 모든 요소의 characterID가 0이 아닌 경우
        if (index === -1) {
          Swal.fire({
            icon: "warning",
            title: "파티가 가득찼습니다.",
            text: "파티원을 클릭하여 제거한 후 다시 추가해주세요.",
            showConfirmButton: false,
            timer: 1500,
          });
          return prevList;
        }

        // 새로운 리스트를 생성하여 불변성을 유지합니다.
        const newList = [...prevList];
        newList[index] = {
          ...newList[index],
          character: character,
          heroCollectionID: heroCollectionID,
        };
        saveState({ target: "myPartyHeroesState", value: newList });
        saveToast();
        return newList;
      });
  };

  const MyHeroes = () => {
    return (
      <div className="mt-1">
        <div className="sectionSubTitle">나의 영웅 정보</div>
        <div className="sectionSubTitleDescription">
          <div>영웅을 클릭하여 팀을 편성하세요.</div>
          <div>
            입력한 정보는 브라우저에 자동저장되며, 쿠키 삭제할 시 초기화됩니다.
          </div>
        </div>
        <div className="sectionItem themeItem1Color themeBorder1Color">
          <div>
            {(heroCollectionData ?? []).map((heroCollectionItem) => {
              // console.log("heroCollectionItem : ", heroCollectionItem);
              return (
                <HeroCollectionItem
                  key={heroCollectionItem.heroCollectionID}
                  heroCollection={heroCollectionItem}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const MyField = () => {
    const hourItems = Array.from({ length: 99 }, (_, index) => ({
      value: index,
      label: index,
    }));

    const minuteItems = Array.from({ length: 60 }, (_, index) => ({
      value: `${index.toString().padStart(2, "0")}`,
      label: `${index.toString().padStart(2, "0")}`,
    }));

    const [hour, setHour] = useState(hourItems[0].value);
    const [minute, setMinute] = useState(minuteItems[0].value);
    const [expNumber, setExpNumber] = useState(1);
    const [expGain, setExpGain] = useState(0);
    const [expGainPerMinute, setExpGainPerMinute] = useState(0);
    const expNumberRef = useRef(null);
    const expGainRef = useRef(null);

    const formatNumber = (value) => {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleWheelExpNumber = (e) => {
      e.preventDefault();
      const delta = Math.sign(e.deltaY);
      setExpNumber((prev) => {
        const newValue = prev - delta;
        if (newValue < 1 || newValue > 3) {
          return prev;
        }
        return newValue;
      });
    };

    const handleWheel = ({ e, target, min, max, value }) => {
      e.preventDefault();
      const delta = Math.sign(e.deltaY);
      target((prev) => {
        const newValue = prev - delta * (value ?? 1);
        if (newValue < min || newValue > (max ?? 999999999999)) {
          return prev;
        }
        return newValue;
      });
    };

    const handleChangeExpNumber = (e) => {
      const value = Number(e.target.value);
      if (value >= 1 && value <= 3) {
        setExpNumber(value);
      }
    };

    const handleChangeExpGain = (e) => {
      const value = Number(e.target.value.replace(/,/g, ""));
      setExpGain(value);
    };

    const handleClickSave = (e) => {
      const nowDate = new Date();
      const newField = {
        date: nowDate,
        hour: Number(hour),
        minute: Number(minute),
        minutes: Number(hour) * 60 + Number(minute),
        expNumber: expNumber,
        expGain: expGain,
        expGainPerMinute: expGainPerMinute,
      };
      setMyFiledList((prevState) => {
        const updatedState = [...prevState];
        updatedState.push(newField);
        saveState({ target: "myFieldsState", value: updatedState });
        return updatedState;
      });
    };

    useEffect(() => {
      const expNumberElement = expNumberRef.current;
      const expGainElement = expGainRef.current;

      if (expNumberElement) {
        expNumberElement.addEventListener(
          "wheel",
          (e) => handleWheel({ e, target: setExpNumber, min: 1, max: 3 }),
          {
            passive: false,
          }
        );
      }
      if (expGainElement) {
        expGainElement.addEventListener(
          "wheel",
          (e) =>
            handleWheel({ e, target: setExpGain, min: 0, value: 10000000 }),
          {
            passive: false,
          }
        );
      }

      return () => {
        if (expNumberElement) {
          expNumberElement.removeEventListener("wheel", handleWheel);
        }
        if (expGainElement) {
          expGainElement.removeEventListener("wheel", handleWheel);
        }
      };
    }, []);

    useEffect(() => {
      const calculateExpGainPerMinute = () => {
        const totalMinutes = Number(hour) * 60 + Number(minute);
        if (totalMinutes > 0 && expNumber > 0) {
          setExpGainPerMinute(
            Math.round(Number(expGain) / expNumber / totalMinutes)
          );
        } else {
          setExpGainPerMinute(0);
        }
      };

      calculateExpGainPerMinute();
    }, [hour, minute, expNumber, expGain]);

    useEffect(() => {}, [myField]);

    const SavedFields = () => {
      const handleClickMyField = ({ myFieldItem, id }) => {
        setMyField({ id: id, item: myFieldItem });
      };
      const handleClickDeleteMyField = () => {
        console.log(myField);
        setMyFiledList((prevState) => {
          const updatedState = [...prevState].filter((myFieldItem, i) => {
            if (i != myField.id - 1) {
              return myFieldItem;
            }
          });
          saveState({ target: "myFieldsState", value: updatedState });
          setMyField({});
          return updatedState;
        });
      };
      const MyFieldItem = ({ myFieldItem, id }) => {
        return (
          <div
            id="MyFieldItem"
            className={`themeItem2Color ${
              myField?.id === id && "themeHighlightItem"
            }`}
            onClick={(e) => handleClickMyField({ myFieldItem, id })}
          >
            <div>{id}</div>
            <div>{dayjs(myFieldItem.date).format("YYYY-MM-DD HH:mm:ss")}</div>
            <div>{`${myFieldItem.hour}시간 ${myFieldItem.minute}분`}</div>
            <div>{myFieldItem.expGainPerMinute.toLocaleString()}</div>
          </div>
        );
      };
      return (
        <div id="SavedFields" className="themeBackgroundColor">
          <div className="columns themeItem1Color">
            <div className="textCenter">번호</div>
            <div className="textCenter">날짜</div>
            <div className="textCenter">시간</div>
            <div className="textCenter">분당 경험치</div>
          </div>
          <div className="container">
            {(myFiledList ?? []).map((myFieldItem, i) => {
              return (
                <MyFieldItem key={i} id={i + 1} myFieldItem={myFieldItem} />
              );
            })}
          </div>
          <div className="footer themeItem1Color">
            <div
              id="DeleteButton"
              onClick={() => {
                handleClickDeleteMyField();
                saveToast();
              }}
            >
              삭제
            </div>
          </div>
        </div>
      );
    };

    return (
      <div id="MyField" className="themeItem2Color themeBorder2Color">
        <div className="flexCenterAll flexDirectionColumn">
          <div>사냥한 시간</div>
          <div
            style={{
              textAlign: "center",
              width: "100%",
            }}
            className="opacity70"
          >
            {hour}시간 {minute}분
          </div>
          <div
            id="WheelPicker"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <WheelPicker
              hourItems={hourItems}
              hourValue={hour}
              onHourChange={setHour}
              minuteItems={minuteItems}
              minuteValue={minute}
              onMinuteChange={setMinute}
            />
          </div>
        </div>
        <div className="flexStartCenter flexDirectionColumn">
          <div>경험치 획득 인원</div>
          <div className="opacity70">{expNumber}</div>
          <div id="ExpNumber" className="h-full flexCenterAll">
            <input
              ref={expNumberRef}
              className="themeFontColor"
              onChange={handleChangeExpNumber}
              value={expNumber}
              type="number"
            />
          </div>
        </div>
        <div className="flexStartCenter flexDirectionColumn">
          <div>획득 경험치</div>
          <div className="opacity70">{Number(expGain).toLocaleString()}</div>
          <div id="ExpGain" className="h-full flexCenterAll">
            <input
              ref={expGainRef}
              className="themeFontColor"
              onChange={handleChangeExpGain}
              value={formatNumber(expGain)}
              type="text"
            />
          </div>
        </div>
        <div className="flexStartCenter flexDirectionColumn">
          <div>분당 획득 경험치(인당)</div>
          <div id="ExpPerMin" className="h-full flexCenterAll">
            {formatNumber(expGainPerMinute)}
            <div id="SaveButtonArea">
              <div
                id="SaveButton"
                className="themeItem2Color themeFontColor"
                onClick={() => handleClickSave()}
              >
                저장
              </div>
            </div>
          </div>
        </div>
        <div className="flexStartCenter flexDirectionColumn w-full">
          <SavedFields />
        </div>
      </div>
    );
  };

  const MyTeam = () => {
    return (
      <div>
        <div className="sectionSubTitle">팀 편성</div>
        <div className="sectionSubTitleDescription">
          <div>현재 경험치 획득 정보를 입력하고 저장할 수 있습니다.</div>
          <div>
            저장된 경험치 획득 정보를 클릭하여 레벨업에 소요되는 시간을 계산할
            수 있습니다.
          </div>
          <div>
            저장한 정보는 브라우저에 자동저장되며, 쿠키 삭제할 시 초기화됩니다.
          </div>
        </div>
        <div className="sectionItem themeItem1Color themeBorder1Color">
          <MyParty
            HeroItem={HeroItem}
            myPartyHeroList={myPartyHeroesList}
            setMyPartyHeroList={setMyPartyHeroesList}
          />
          <div className="mt-1">
            현재 경험치 획득 정보 (마우스휠로 입력 가능)
          </div>
          <MyField />
        </div>
      </div>
    );
  };

  const ScrollZone = ({ scrollRefs }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const scrollToTarget = (index) => {
      // scrollRefs.current.style.scrollMargin = headerBarHeight;
      scrollRefs.current[index].scrollIntoView({ behavior: "smooth" });
    };

    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };

    return (
      <div id="ScrollButtonArea" className="themeItem1Color">
        <div
          id="ScrollToggleButton"
          className="themeItem2Color themeFontColor"
          onClick={toggleExpand}
        >
          {isExpanded ? "닫기" : "바로가기 열기"}
        </div>
        <div id="ScrollButtonWrapper" className={isExpanded ? "expanded" : ""}>
          <div
            id="ScrollButton"
            className="themeItem2Color"
            onClick={scrollToTop}
          >
            ▲ 위로
          </div>
          {heroCollectionData.map((heroCollection) => {
            return (
              <div
                id="ScrollButton"
                className="themeItem2Color"
                key={heroCollection.heroCollectionID}
                onClick={() => scrollToTarget(heroCollection.heroCollectionID)}
              >
                {heroCollection.name}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div id="HeroCollection" ref={(el) => (scrollRefs.current[0] = el)}>
      <MyTeam />
      <MyHeroes />
      <ScrollZone scrollRefs={scrollRefs} />
    </div>
  );
};

export default HeroCollection;
