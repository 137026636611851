import React, { useState, useEffect, useRef, useMemo } from "react";
import dayjs from "dayjs";
import "assets/styles/BossTimerStyle.css";
// import ReboldoeuxImg from "assets/images/img_reboldoeux_2.jpg";
// import CoimbraImg from "assets/images/img_coimbra_3.jpg";
// import AuchImg from "assets/images/img_auch_2.jpg";
import ReboldoeuxCoimbraImg from "assets/images/img_reboldoeux_coimbra.png";
import AuchUstiurImg from "assets/images/img_auch_ustiur.png";
import ㅌㄷㅇㄹ from "assets/images/tder.png";
import { Reply } from "components/common/reply/Reply";

const calculateTimesOnDate = (baseTime, targetDate, intervalHours) => {
  const intervalMilliseconds = intervalHours * 60 * 60 * 1000;
  const times = [];

  // 기준 시간과 계산할 날짜의 시작 시간 설정
  const startTime = new Date(targetDate.getTime());
  startTime.setHours(0, 0, 0, 0);

  // 기준 시간으로부터 계산할 날짜의 시작 시간까지의 시간 차이 계산
  let nextTime = new Date(baseTime);
  while (nextTime < startTime) {
    nextTime = new Date(nextTime.getTime() + intervalMilliseconds);
  }

  // 계산할 날짜의 범위 내에서 10시간 간격으로 시간 추가
  while (nextTime.getDate() === targetDate.getDate()) {
    times.push(new Date(nextTime));
    nextTime = new Date(nextTime.getTime() + intervalMilliseconds);
  }

  return times;
};

const bossTimeArrayOnDate = ({ target, targetDate }) => {
  //   const [intervalHours, setIntervalHours] = useState(0);
  var intervalHours = 0;
  switch (target) {
    case "리볼도외":
    case "코임브라":
      //   setIntervalHours(10);
      intervalHours = 10;
      break;
    case "오슈":
      //   setIntervalHours(21);
      intervalHours = 21;
      break;
  }

  const baseTime = new Date("2024-06-17T10:00:00");
  const timesOnDate = calculateTimesOnDate(baseTime, targetDate, intervalHours);
  return timesOnDate;
};

const timeDurationBetween = ({ time1, time2 }) => {
  // 두 시간 사이의 전체 차이를 초 단위로 계산
  const diffInSeconds = time2.diff(time1, "second");

  // 초 단위의 차이를 일, 시, 분, 초로 변환
  const days = Math.floor(diffInSeconds / 86400);
  const hours = Math.floor((diffInSeconds % 86400) / 3600);
  const minutes = Math.floor((diffInSeconds % 3600) / 60);
  const seconds = diffInSeconds % 60;

  // 일, 시간, 분, 초 형식으로 포맷팅
  let formattedTime = "";
  if (days > 0) {
    formattedTime += `${days}일 `;
  }
  if (hours > 0) {
    formattedTime += `${hours}시간 `;
  }
  if (minutes > 0) {
    formattedTime += `${minutes}분 `;
  }
  if (seconds > 0) {
    formattedTime += `${seconds}초`;
  }

  // 결과가 없을 경우 "0초"를 반환
  if (formattedTime === "") {
    formattedTime = "0초";
  }

  return { diffInSeconds, formattedTime };
};

const CurrentTime = ({ currentTime }) => {
  return (
    <div id="CurrentTime">
      <div id="Date">{dayjs(currentTime).format("YYYY년 MM월 DD일")}</div>
      <div id="Time">{currentTime.toLocaleTimeString()}</div>
    </div>
  );
};

const BossTimeBriefShow = ({ target, currentTime, targetDate }) => {
  let bossTimeArray = bossTimeArrayOnDate({ target, targetDate });
  let array = [];
  (bossTimeArray ?? []).forEach((bossTimeItem) => {
    let timeDurationObject = timeDurationBetween({
      time1: dayjs(currentTime),
      time2: dayjs(bossTimeItem),
    });
    if (timeDurationObject.diffInSeconds > 0)
      array.push(
        <div
          key={bossTimeItem}
          className={`bossTimeItem ${
            timeDurationObject.diffInSeconds < 0 ? "fontColorGrey" : ""
          }`}
        >
          {/* <div>{dayjs(bossTimeItem).format("A h:mm")}</div> */}
          <div>{bossTimeItem.toLocaleTimeString().split(":")[0]}시</div>
          {/* <div>{dayjs(bossTimeItem).format("HH:mm:ss")}</div> */}
          <div>
            {timeDurationObject.diffInSeconds < 0
              ? ""
              : `( ${timeDurationObject.formattedTime} 남음 )`}
          </div>
        </div>
      );
  });
  // let array = (bossTimeArray ?? []).map((bossTimeItem) => {
  //   let timeDurationObject = timeDurationBetween({
  //     time1: dayjs(currentTime),
  //     time2: dayjs(bossTimeItem),
  //   });
  //   return (
  //     <div
  //       key={bossTimeItem}
  //       className={`bossTimeItem ${
  //         timeDurationObject.diffInSeconds < 0 ? "fontColorGrey" : ""
  //       }`}
  //     >
  //       {/* <div>{dayjs(bossTimeItem).format("A h:mm")}</div> */}
  //       <div>{bossTimeItem.toLocaleTimeString().split(":")[0]}시</div>
  //       <div>
  //         {timeDurationObject.diffInSeconds < 0
  //           ? ""
  //           : `( ${timeDurationObject.formattedTime} 남음 )`}
  //       </div>
  //     </div>
  //   );
  // });
  if (array.length === 0) {
    return <div className="bossTimeArea fontColorGrey">남은 보스 없음</div>;
  } else {
    return <div className="bossTimeArea">{array}</div>;
  }
};

const BossTimerBrief = ({ currentTime }) => {
  let tomorrowTime = dayjs(currentTime).add(1, "day").toDate();
  return (
    <div id="BossTimerBrief">
      {/* <div className="item">
        <div className="title">리볼도외 (빅 코카트리스)</div>
        <div className="imageBox">
          <img src={ReboldoeuxImg} alt="Reboldoeux" />
          <div className="bossDurationHours">10H</div>
        </div>
        <div id="date1" className="date">
          오늘 ( {dayjs(currentTime).format("YYYY년 MM월 DD일")} )
        </div>
        <BossTimeBriefShow
          target="리볼도외"
          currentTime={currentTime}
          targetDate={currentTime}
        />
        <div id="date2" className="date">
          내일 ( {dayjs(tomorrowTime).format("YYYY년 MM월 DD일")} )
        </div>
        <BossTimeBriefShow
          target="리볼도외"
          currentTime={currentTime}
          targetDate={tomorrowTime}
        />
      </div> */}
      <div className="item">
        <div className="title">리볼도외, 코임브라</div>
        <div className="imageBox">
          <img src={ReboldoeuxCoimbraImg} alt="Coimbra" />
          <div className="bossDurationHours">10H</div>
        </div>
        <div id="date3" className="date">
          오늘 ( {dayjs(currentTime).format("YYYY년 MM월 DD일")} )
        </div>
        <BossTimeBriefShow
          target="코임브라"
          currentTime={currentTime}
          targetDate={currentTime}
        />
        <div id="date4" className="date">
          내일 ( {dayjs(tomorrowTime).format("YYYY년 MM월 DD일")} )
        </div>
        <BossTimeBriefShow
          target="코임브라"
          currentTime={currentTime}
          targetDate={tomorrowTime}
        />
      </div>
      <div className="item">
        <div className="title">오슈, 로스롤도스, 우스티우르</div>
        <div className="imageBox">
          <img src={AuchUstiurImg} alt="Auch" />
          <div className="bossDurationHours">21H</div>
        </div>
        <div id="date5" className="date">
          오늘 ( {dayjs(currentTime).format("YYYY년 MM월 DD일")} )
        </div>
        <BossTimeBriefShow
          target="오슈"
          currentTime={currentTime}
          targetDate={currentTime}
        />
        <div id="date6" className="date">
          내일 ( {dayjs(tomorrowTime).format("YYYY년 MM월 DD일")} )
        </div>
        <BossTimeBriefShow
          target="오슈"
          currentTime={currentTime}
          targetDate={tomorrowTime}
        />
      </div>
    </div>
  );
};

export const BossTimerSection = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const timeRef = useRef(currentTime);
  const memoizedReply = useMemo(
    () => <Reply table="BossTimer" title="방명록" />,
    []
  );

  useEffect(() => {
    const interval = setInterval(() => {
      timeRef.current = new Date();
      setCurrentTime(timeRef.current);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handle = requestAnimationFrame(() => {
      setCurrentTime(timeRef.current);
    });
    return () => cancelAnimationFrame(handle);
  }, [currentTime]);

  return (
    <div id="BossTimer" className="page themeFontColor">
      <div className="pageItem">
        <div className="sectionSubTitle">보스 타이머</div>
        <div className="sectionItem themeItem1Color themeBorder1Color">
          <CurrentTime currentTime={currentTime} />
          <BossTimerBrief currentTime={currentTime} />
        </div>
      </div>
      {/* <div className="pageItem">
        <div className="sectionSubTitle">광고</div>
        <div className="sectionItem themeItem1Color themeBorder1Color flexCenterAll flexDirectionColumn">
          <img id="ㅌㄷㅇㄹ" src={ㅌㄷㅇㄹ} />
          <div className="mt-1">made by 한울당 Diablo</div>
          <div className="mt-1 fontSize24px">지금 ㅌㄷㅇㄹ 하세요</div>
        </div>
      </div> */}
      {memoizedReply}
      {/* <MemoReply table="BossTimer" title="방명록" /> */}
    </div>
  );
};
