export const loadState = ({ target, value }) => {
  const state = localStorage.getItem(target);
  if (state) {
    return JSON.parse(state);
  } else {
    return value;
  }
};

export const saveState = ({ target, value }) => {
  localStorage.setItem(target, JSON.stringify(value));
};
