import * as jose from 'jose';
import {
  JWTAlgorithm,
  publicKeyAlgorithm,
  publicKeyJWK,
} from 'assets/configs/keys';
import { HOST_URL } from 'assets/configs/constants';

const verifyToken = async (jwt) => {
  console.log('Verify Token JWT : ', jwt);
  try {
    /** 1. Public Key(JWK) Import */
    const publicKey = await jose.importJWK(publicKeyJWK, JWTAlgorithm);
    console.log('publicKey : ', publicKey);
    /** 2. Decrypt JWT and return payload */
    var { payload } = await jose.jwtVerify(jwt, publicKey, {
      // issuer: `https://${HOST_URL}`,
      // audience: `https://${HOST_URL}`,
    });
    console.log('Token Success : ', payload);
    console.log(new Date(payload.exp * 1000));
    return payload;
  } catch (error) {
    console.log('Token Error : ', error);
    throw error;
  }
};

export default verifyToken;
