import "assets/styles/NavigationStyle.css";
import { Link } from "react-router-dom";

export const Navigation = () => {
  return (
    <div id="Navigation">
      <div id="NavigationItem" className="themeFontColor">
        <Link to="/BossTimer">보스 타이머</Link>
      </div>
      <div id="NavigationItem" className="themeFontColor">
        <Link to="/Applications">어플리케이션</Link>
      </div>
      <div id="NavigationItem" className="themeFontColor">
        <Link to="/TacticArticles">정보 & 공략</Link>
      </div>
      <div id="NavigationItem" className="themeFontColor">
        <Link to="/SummonCharm">소환 부적</Link>
      </div>
    </div>
  );
};
