import "assets/styles/LoginStyle.css";
import API from "functions/API";
import { useState } from "react";
import sha256 from "sha256";
import * as jose from "jose";
import { publicKeyAlgorithm, publicKeyJWK } from "assets/configs/keys";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { authState } from "components/common/Zustand";

export const Login = () => {
  const [userID, setUserID] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies([
    "refreshToken",
    "userID",
  ]);
  const { setAuth, setAccessToken, setRefreshToken } = authState();

  const onLogin = async (event) => {
    event.preventDefault(); // 폼의 기본 동작(페이지 새로고침)을 막음

    /** 1. Public Key(JWK) Import */
    const publicKey = await jose.importJWK(publicKeyJWK, publicKeyAlgorithm);
    /** 2. Encrypt user information with JWE */
    const jwe = await new jose.CompactEncrypt(
      new TextEncoder().encode(sha256(userPassword))
    )
      .setProtectedHeader({ alg: "RSA-OAEP-256", enc: "A256GCM" })
      .encrypt(publicKey);
    const postLogin = await API.post("/api/v1/auth/login", {
      userID,
      userPassword: jwe,
    })
      .then(async (response) => {
        let refreshToken = response.data.refreshToken;
        let accessToken = response.data.accessToken;
        /** 4. Set JWT in Cookie with httpOnly and secure */
        setCookie("refreshToken", refreshToken, {
          // httpOnly: true,
          secure: true,
          // sameSite: true,
        });
        setCookie("userID", userID, {
          // httpOnly: true,
          secure: true,
          // sameSite: true,
        });
        setAccessToken(accessToken);
        /** 5. Navigate to valid path */
        navigate("/");
      })
      .then(() => {})
      /** (3-5)E. Error handling */
      .catch((error) => {
        console.log("login error : ", error);
      });
  };

  return (
    <div id="LoginPage">
      <div id="Login" className="sectionItem themeItem1Color themeBorder1Color">
        <div id="Title" className="themeFontColor">
          관리자 로그인
        </div>
        <form onSubmit={onLogin} className="themeBorder2Color">
          <div>
            <label>
              <input
                className="themeItem2Color themeBorder2Color themeFontColor"
                type="text"
                value={userID}
                onChange={(e) => setUserID(e.target.value)}
                required
                placeholder="ID"
              />
            </label>
          </div>
          <div className="mt-1">
            <label>
              <input
                className="themeItem2Color themeBorder2Color themeFontColor"
                type="password"
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                required
                placeholder="Password"
              />
            </label>
          </div>
          <button id="LoginButton" type="submit" className="mt-2">
            로그인
          </button>
        </form>
      </div>
    </div>
  );
};
