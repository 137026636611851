import API from "functions/API";
import { memo, useEffect, useState, useCallback, useMemo, useRef } from "react";
import "assets/styles/ReplyStyle.css";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import sha256 from "sha256";
import { deleteToast, submitToast } from "functions/toast";

const ReplyComponent = ({ table, title }) => {
  const [replies, setReplies] = useState([]);
  const [writer, setWriter] = useState({
    writerName: "",
    writerPassword: "",
  });
  const [content, setContent] = useState("");
  const [render, setRender] = useState(false);

  const textareaRef = useRef(null);
  useEffect(() => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto"; // Reset height to auto to get the correct scrollHeight
    textarea.style.height = `${textarea.scrollHeight}px`; // Set height to scrollHeight
  }, [content]);

  useEffect(() => {
    const getRepliesByTable = async () => {
      let response = await API.get(`api/v1/replies/${table}`);
      setReplies(response.data);
    };
    getRepliesByTable();
  }, [render, table]);

  const handleWriterNameChange = useCallback((e) => {
    setWriter((prevState) => ({
      ...prevState,
      writerName: e.target.value,
    }));
  }, []);

  const handleWriterPasswordChange = useCallback((e) => {
    setWriter((prevState) => ({
      ...prevState,
      writerPassword: e.target.value,
    }));
  }, []);

  const handleContentChange = useCallback((e) => {
    setContent(e.target.value);
  }, []);

  const handleClickSubmitButton = () => {
    var errorMessage = "";
    if (writer.writerName.trim() == "") {
      errorMessage = "작성자명이 비어있습니다";
    } else if (writer.writerPassword.trim() == "") {
      errorMessage = "패스워드가 비어있습니다";
    } else if (content.trim() == "") {
      errorMessage = "내용이 비어있습니다";
    }
    if (errorMessage !== "") {
      Swal.fire({
        icon: "warning",
        title: errorMessage,
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }

    Swal.fire({
      icon: "question",
      title: "작성을 완료하시겠습니까?",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response = await API.post(`api/v1/replies/${table}`, {
            writerName: writer.writerName.trim(),
            writerPassword: sha256(writer.writerPassword),
            content,
          });
          console.log("response : ", response);
          submitToast();
          setRender(!render);
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  const handleClickReviseButton = async ({ idx, reviseContent }) => {
    console.log("content : ", reviseContent);
    await Swal.fire({
      title: "패스워드를 입력하세요",
      input: "password",
      inputPlaceholder: "패스워드",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    }).then(async (result) => {
      try {
        if (result.isConfirmed) {
          console.log(result.value);
          console.log(idx);
          const response = await API.patch(`api/v1/replies/${table}`, {
            idx: idx,
            replyPassword: sha256(result.value),
            replyContent: reviseContent,
          });
          console.log(response);
          submitToast();
          setRender(!render);
        }
      } catch (error) {
        console.log("error : ", error);
        Swal.fire({
          icon: "error",
          title: "잘못된 패스워드입니다",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    });
  };

  const handleClickDeleteButton = async ({ idx }) => {
    await Swal.fire({
      title: "패스워드를 입력하세요",
      input: "password",
      inputPlaceholder: "패스워드",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    }).then(async (result) => {
      try {
        if (result.isConfirmed) {
          console.log(result.value);
          console.log(idx);
          const response = await API.delete(`api/v1/replies/${table}`, {
            data: {
              idx: idx,
              replyPassword: sha256(result.value),
            },
          });
          console.log(response);
          deleteToast();
          setRender(!render);
        }
      } catch (error) {
        console.log("error : ", error);
        Swal.fire({
          icon: "error",
          title: "잘못된 패스워드입니다",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    });
  };

  const Replies = ({ replies }) => {
    let [reviseContent, setReviseContent] = useState({});
    let [reviseClicked, setReviseClicked] = useState({});
    const textareaRefs = useRef({});

    const handleReviseContent = ({ idx, content }) => {
      setReviseContent((prevState) => ({
        ...prevState,
        [idx]: content,
      }));
    };

    const handleReviseClick = (idx) => {
      setReviseClicked((prevState) => ({
        ...prevState,
        [idx]: !prevState[idx],
      }));
    };

    useEffect(() => {
      Object.values(textareaRefs.current).forEach((textarea) => {
        if (textarea) {
          textarea.style.height = "auto"; // Reset height to auto to get the correct scrollHeight
          textarea.style.height = `${textarea.scrollHeight}px`; // Set height to scrollHeight
        }
      });
    }, [reviseContent]);

    return (replies ?? []).map((replyItem) => (
      <div
        id="RepliesItem"
        className="themeItem2Color themeBorder2Color"
        key={replyItem.idx}
      >
        <div className="writerName">
          {replyItem.writerName}
          <span className="writerIP">
            {replyItem.writerIP.split(":")[3] ?? "localhost"}
          </span>
        </div>
        <div className="content">
          {reviseClicked[replyItem.idx] ? (
            <textarea
              ref={(el) => (textareaRefs.current[replyItem.idx] = el)}
              className="themeFontColor themeBorder2Color"
              type="text"
              value={reviseContent[replyItem.idx] || replyItem.content}
              onChange={(e) =>
                handleReviseContent({
                  idx: replyItem.idx,
                  content: e.target.value,
                })
              }
            />
          ) : (
            <p>{replyItem.content}</p>
          )}
        </div>
        <div className="date">
          {dayjs(replyItem.createdAt).format("YYYY.MM.DD HH:mm")}
        </div>
        {reviseClicked[replyItem.idx] ? (
          <div className="buttonArea">
            <div
              id="SubmitButton"
              className="themeBorder2Color themeFontColor"
              onClick={() => handleReviseClick(replyItem.idx)}
            >
              취소
            </div>
            <div
              id="SubmitButton"
              className="themeBorder2Color themeFontColor"
              onClick={() =>
                handleClickReviseButton({
                  idx: replyItem.idx,
                  reviseContent: reviseContent[replyItem.idx],
                })
              }
            >
              등록
            </div>
          </div>
        ) : (
          <div className="buttonArea">
            <div
              id="SubmitButton"
              className="themeBorder2Color themeFontColor"
              onClick={() => handleReviseClick(replyItem.idx)}
            >
              수정
            </div>
            <div
              id="SubmitButton"
              className="themeBorder2Color themeFontColor submitButtonColorDarkRed"
              onClick={() => handleClickDeleteButton({ idx: replyItem.idx })}
            >
              삭제
            </div>
          </div>
        )}
      </div>
    ));
  };

  const ReplyForm = useMemo(() => {
    return (
      <div id="Reply" className="themeItem2Color themeBorder2Color">
        <div id="Writer">
          <div className="information">
            <p>작성자</p>
            <input
              className="writerName themeBorder1Color themeFontColor ml-half"
              type="text"
              maxLength={20}
              placeholder="작성자명"
              onChange={handleWriterNameChange}
              value={writer.writerName}
            />
          </div>
          <div className="information">
            <p>패스워드</p>
            <input
              className="writerPassword themeBorder1Color themeFontColor ml-half"
              type="password"
              required
              autoComplete="new-password"
              minLength={1}
              maxLength={20}
              placeholder="패스워드"
              onChange={handleWriterPasswordChange}
              value={writer.writerPassword}
            />
          </div>
        </div>
        <div id="BorderBetween" className="themeBorder2Color mt-half" />
        <div id="Content">
          <textarea
            ref={textareaRef}
            className="content themeFontColor"
            onChange={handleContentChange}
            value={content}
            wrap="hard"
            placeholder="싸우지마세요"
          />
          <div className="buttonArea">
            <div
              id="SubmitButton"
              className="themeBorder2Color themeFontColor"
              onClick={handleClickSubmitButton}
            >
              등록
            </div>
          </div>
        </div>
      </div>
    );
  }, [writer.writerName, writer.writerPassword, content]);

  return (
    <div id="ReplyComponent" className="pageItem">
      <div>
        <span className="sectionSubTitle">{title}</span>
      </div>
      <div className="sectionItem themeItem1Color themeBorder1Color themeFontColor">
        <Replies replies={replies} />
        <div id="BorderBetween" className="themeBorder1Color mt-2" />
        {ReplyForm}
      </div>
    </div>
  );
};

export const Reply = memo(ReplyComponent);
