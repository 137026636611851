import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useCookies } from "react-cookie";
import { authState } from "components/common/Zustand";
import Swal from "sweetalert2";
import API from "functions/API";
import { FaEye } from "react-icons/fa";
import CopyToClipboard from "react-copy-to-clipboard";
import { Helmet } from "react-helmet";

const TacticArticleView = ({ categoryOptions }) => {
  const { auth } = authState();
  const { articleID } = useParams();
  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [cookies, setCookie] = useCookies([`visitor-${articleID}`]);
  const navigate = useNavigate();

  useEffect(() => {
    API.get(`/api/v1/tacticArticles/${articleID}`).then((response) => {
      let articleData = response.data;
      setArticle(articleData);
      setIsLoading(false);
    });

    const visitorId = cookies[`visitor-${articleID}`];
    if (!visitorId) {
      API.post(`/api/v1/tacticArticles/${articleID}`).then(() => {
        const newVisitorId = `${Date.now()}-${Math.random()}`;
        setCookie(`visitor-${articleID}`, newVisitorId, {
          expires: new Date(Date.now() + 86400000),
        }); // 1 day
      });
    }
  }, [articleID, cookies, setCookie]);

  const handleClickDelete = async ({ articleID }) => {
    Swal.fire({
      icon: "question",
      title: "글을 삭제하시겠습니까?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "예",
      cancelButtonText: "아니요",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        API.delete(`/api/v1/tacticArticles/${articleID}`).then((response) => {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "글 삭제를 완료하였습니다.",
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/TacticArticles");
        });
      }
    });
  };

  const handleCopy = () => {
    Swal.fire({
      icon: "success",
      title: "URL이 복사되었습니다.",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  return (
    <div id="Viewer">
      <Helmet>
        <meta property="og:title" content={article?.subject || "Loading..."} />
        {/* <meta
          property="og:description"
          content={article?.description || "Loading..."}
        /> */}
        <meta
          property="og:image"
          content={article?.thumbnailUrl || "default-image-url"}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="사이트 이름" />
      </Helmet>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
      <div id="Category" className="sectionSubTitle">
        {article?.category}
      </div>
      <div className="sectionItem themeItem1Color themeBorder1Color">
        <div id="SubjectArea">
          <div id="SubjectLeftArea">
            <div id="Subject">{article?.subject}</div>
            <div id="Writer" className="sectionSubTitleDescription">
              written by {article?.Writer?.userName}
            </div>
            <div id="CreatedAt" className="sectionSubTitleDescription">
              {dayjs(article?.createdAt).format("YYYY.MM.DD hh:mm:ss A")}
            </div>
          </div>
          <div id="SubjectRightArea">
            <div id="URLCopy">
              <CopyToClipboard text={window.location.href} onCopy={handleCopy}>
                <div id="CopyButton">URL 복사</div>
              </CopyToClipboard>
            </div>
            <div id="ReadCount">
              <div className="flexCenterAll">
                <FaEye />
              </div>
              <div className="ml-half">{article?.readCount}</div>
            </div>
          </div>
        </div>
        <div id="Content" className="themeBorder1Color">
          <div dangerouslySetInnerHTML={{ __html: article?.content }} />
        </div>
        <div id="ButtonArea" className="themeBorder1Color">
          {auth?.userID === article?.writerID && (
            <div>
              <div
                id="DeleteButton"
                onClick={() => handleClickDelete({ articleID: articleID })}
              >
                삭제
              </div>
            </div>
          )}
          {auth?.userID === article?.writerID && (
            <div>
              <Link
                to={{
                  pathname: `/TacticArticles/${article?.idx}/revise`,
                  state: { isPageValid: true, article, categoryOptions },
                }}
              >
                <div id="ReviseButton">수정</div>
              </Link>
            </div>
          )}
          <div>
            <Link to={"/TacticArticles"}>
              <div id="ListButton">목록</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TacticArticleView;
