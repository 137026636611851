import { ReactSelectStyles } from "components/common/ReactSelectStyle";
import TextEditor from "components/common/TextEditor";
import { authState } from "components/common/Zustand";
import API from "functions/API";
import { uploadFiles } from "functions/aws/myAWS";
import { useEffect, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { MdOutlineUploadFile } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Swal from "sweetalert2";

export const TacticArticleRevise = ({ categoryOptions }) => {
  const { auth } = authState();
  const { articleID } = useParams();
  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태 추가
  const editorRef = useRef(null);
  const [subject, setSubject] = useState("");
  const [category, setCategory] = useState("");
  const [thumbnailFileObject, setThumbnailFileObject] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { isPageValid, article: articleData } = location.state || {};

  useEffect(() => {
    API.get(`api/v1/tacticArticles/${articleID}`).then((response) => {
      let articleData = response.data;
      setArticle(articleData);
      setSubject(articleData.subject);
      setIsLoading(false); // 데이터 로딩 완료 후 로딩 상태 업데이트
    });
  }, []);

  useEffect(() => {
    // console.log(articleData);
    // console.log(articleData?.category);

    (categoryOptions ?? []).filter((categoryOptionItem) => {
      if (categoryOptionItem.label === article?.category) {
        console.log(categoryOptionItem);
        setCategory(categoryOptionItem);
      }
    });
  }, [article]);

  const saveUploadFile = ({ fileObject, setFileObject }) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileObject?.file);
    reader.onloadend = () => {
      setFileObject((prevState) => ({
        ...fileObject,
        showFile: reader.result,
      }));
    };
  };

  const handleSubmit = async ({ editorRef }) => {
    const showWarning = (title) => {
      Swal.fire({
        icon: "warning",
        title,
        showConfirmButton: false,
        timer: 1500,
      });
    };

    if (!category) {
      showWarning("카테고리를 선택해주세요.");
      return;
    }

    if (!subject) {
      showWarning("제목을 기입해주세요.");
      return;
    }

    const content = editorRef.current.getContent();
    const contentSizeMB =
      new Blob([content], { type: "text/html" }).size / (1024 * 1024);

    // if (contentSizeMB > 10) {
    //   showWarning("내용의 용량이 10MB를 초과합니다. 내용을 줄여주세요.");
    //   return;
    // }

    const confirmed = await Swal.fire({
      icon: "question",
      title: "글 수정을 완료하시겠습니까?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "예",
      cancelButtonText: "아니요",
      reverseButtons: true,
    });

    if (!confirmed.isConfirmed) return;

    let uploadResultUrl = "";

    Swal.fire({
      icon: "info",
      title: "업로드 중입니다.",
      text: "잠시만 기다려주세요...",
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    if (thumbnailFileObject?.file) {
      const uploadResponse = await uploadFiles({
        files: [thumbnailFileObject.file],
        key: "tacticArticles/thumbnails",
        withProgressBar: true,
      });

      console.log("CHECK : ", uploadResponse[0]);
      if (uploadResponse[0]?.status !== 200) {
        Swal.fire({
          icon: "error",
          title: "썸네일 업로드 실패",
          text: "다시 시도해주세요.",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }

      uploadResultUrl = uploadResponse[0]?.config?.url;
    }

    try {
      const response = await API.patch(
        `api/v1/tacticArticles/${article?.idx}`,
        {
          writerID: auth?.userID,
          category: category.label,
          subject,
          thumbnailUrl: uploadResultUrl,
          content,
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "수정이 완료되었습니다.",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/TacticArticles");
      } else {
        throw new Error("Response status is not 200");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "오류가 발생했습니다.",
        footer:
          "관리자에게 문의해주세요. ( 그라나도에스파다모바일 펠리페서버 한울당 오늘날씨 )",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <div id="Write" className="sectionItem themeItem1Color themeBorder1Color">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
      <div id="CategorySelectArea">
        <CreatableSelect
          styles={ReactSelectStyles({ type: "category" })}
          options={categoryOptions}
          menuPlacement="auto"
          placeholder="카테고리 (원하는게 없으면 직접 기입으로 생성)"
          onChange={(e) => setCategory(e)}
          isSearchable
          isClearable
          value={category}
        />
      </div>
      <input
        id="SubjectInput"
        className="themeBorder1Color"
        type="textarea"
        placeholder="제목"
        onChange={(e) => setSubject(e.target.value)}
        defaultValue={article?.subject}
      />
      <TextEditor editorRef={editorRef} article={article} />
      <div id="ButtonArea">
        <div
          id="ThumbnailUploader"
          className="themeItem1Color themeBorder2Color"
        >
          <FileUploader
            handleChange={(file) =>
              saveUploadFile({
                fileObject: { file },
                setFileObject: setThumbnailFileObject,
              })
            }
            children={
              <div id="children">
                {thumbnailFileObject?.showFile ? (
                  <img src={thumbnailFileObject?.showFile} alt="thumbnail" />
                ) : (
                  <div>
                    <MdOutlineUploadFile size={30} />
                    썸네일 업로드
                  </div>
                )}
              </div>
            }
          />
        </div>
        <button id="SubmitButton" onClick={() => handleSubmit({ editorRef })}>
          수정 완료
        </button>
      </div>
    </div>
  );
};
