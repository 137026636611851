import API from 'functions/API';

const refreshAccessToken = async ({ cookies }) => {
  // console.log('Sent Cookies : ', cookies);
  return await API.post('/api/v1/auth/refreshToken', {
    cookies,
  })
    .then((response) => {
      // console.log('RefreshAccessToken Success : ', response.data.accessToken);
      return response.data.accessToken;
    })
    .catch((error) => {
      // console.log('RefreshAccessToken Fail : ', error);
      throw error;
    });
};

export default refreshAccessToken;
