const privateKeyPKCS8 =
  '-----BEGIN PRIVATE KEY-----MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCsTqVOLxu736CSZ4Mlp9JXvQVzC/fySDu6w76ZtLzeZg62lEthsp0CTl4ILQw6Tipk0clWsc6RL0v0gS4lQB4/aerP/H1XlFjJMPIdAOulxndJSOaCM0sIl5gTWUmI4hnfsz5Tp5Fv80IYOrVlNiNsSm82QAWRiWBJhq0FWrrOvSXnT57inLjy4RJOF9sNJOs6jUNKjefvwfcRbhywTrNH1e4SNmxT2ovNAwGuYs2QYtEonetPxUSFFxY1fWUXipdaYijV+1Lvf3/A/hw6COrkoji0xTp6990WJ/rFzTsWqi45z9uDzgat0xedkakGyQxPKTtAF/bLEII1+A+rC/AFAgMBAAECggEBAJzKN6uMPE1ZsXBnVrRGIKYL71B/vJAv4IITKnYNO6uX7J2BFZMCB9MYYoJIE49enz78kpwb0d7UzeXgQpBM5vlHNQ8rLxcpex0gN7/inYtMrDD4cCBb8lQQNaatobc4Htkq7nUKztGy5hrMGVHO0K7ykH4qBacn/YEKVpi/tar2fqAmRJePZf46Va0W6OwvHqlEuNFjQu5SwOw7PW4uXFOeULC4la86ldmxtylz0PkbqJRu9Ap+tOo8gXG4iy7xrsW3Qajzy0B9uJVuA25OxFUw7VyUFuIq/y3CaxQdK52nhsw4sk1y7pW6TZXWnq+xPPQFnGLwhIbXrbEPOM/JdmECgYEA1kIY1HSjTgBbLQAgiJ0s8zaASXvvR+lTiOj6ka4JdDwbcxSduH1scuafbDvrDNcC7AVohvh7D18/gF1XdPgOXadHY4S7DRpRmVoYQmBfeB9yxO5sQxAExlNPKJarybttK34kQ6cP2Q1kRzFJL73HpsjQ8NihWewaZzMVQ13gi40CgYEAzeBJlezhvrfh2g9YRA5gWTPleSgCA7WmsvMsRXsujobY0PHfKZbjHSTx/SN3w42qxWaOQ2wInujIurj+216dj1+MvPrbXaTApxdjgW0YvGVbQxIujl0dTF/tA9UGKumhFzPACH7ib1Z25WfOCplI+vAuVMyFJWjuMiefuVGYHFkCgYB57W1R4fpY1i0Mo8jf1YoR8g75QnT92FL4U5TcOE0a/2WjjLTgm0eO750Dq+lsCiOYis9cF6V4LvzlILk3UD5kn2ApywvjPfn3qeSqSUurAUJ/vSqQ01nUPu6KK4whJe7PVF5wvW5wV4WcWfjLGVW3nQoCNVVE5e50kgZkqtSwtQKBgCMMXNqmMiQ6v7tUw20KmYJ2IEnI8tbY1OFAHrhRaTKgaEbh9mDpLA7pHD1BDYGAG6NxeySlPq526L1JAzIrYF1pIdeGSVQe2SFZDPqarQxdoPTBIqXf2j4HUp1FZcBmd6Mwjy+jEj9sE9+oFV6ei9sUin6rNruQqoRlVH7DEkc5AoGAJqanWm9afBsmSb1SuUT1bnYGfJsU2n8Z3mzMRWGvZ2RM1A3evj/J1prgCzqUD6SVv/hwl3UDPAoAVbvoZEpr1Et9HYEcqUUq9TbuklYbR3HRpaHqrsp5j2y7TeP8sW1MPG1SqJa0zqWHqEa8nTopbTixNOlNblTcT1s96YcFK0Q=-----END PRIVATE KEY-----';
const privateKeyJWK = {
  kty: 'RSA',
  n: 'rE6lTi8bu9-gkmeDJafSV70Fcwv38kg7usO-mbS83mYOtpRLYbKdAk5eCC0MOk4qZNHJVrHOkS9L9IEuJUAeP2nqz_x9V5RYyTDyHQDrpcZ3SUjmgjNLCJeYE1lJiOIZ37M-U6eRb_NCGDq1ZTYjbEpvNkAFkYlgSYatBVq6zr0l50-e4py48uESThfbDSTrOo1DSo3n78H3EW4csE6zR9XuEjZsU9qLzQMBrmLNkGLRKJ3rT8VEhRcWNX1lF4qXWmIo1ftS739_wP4cOgjq5KI4tMU6evfdFif6xc07FqouOc_bg84GrdMXnZGpBskMTyk7QBf2yxCCNfgPqwvwBQ',
  e: 'AQAB',
  d: 'nMo3q4w8TVmxcGdWtEYgpgvvUH-8kC_gghMqdg07q5fsnYEVkwIH0xhigkgTj16fPvySnBvR3tTN5eBCkEzm-Uc1DysvFyl7HSA3v-Kdi0ysMPhwIFvyVBA1pq2htzge2SrudQrO0bLmGswZUc7QrvKQfioFpyf9gQpWmL-1qvZ-oCZEl49l_jpVrRbo7C8eqUS40WNC7lLA7Ds9bi5cU55QsLiVrzqV2bG3KXPQ-RuolG70Cn606jyBcbiLLvGuxbdBqPPLQH24lW4Dbk7EVTDtXJQW4ir_LcJrFB0rnaeGzDiyTXLulbpNldaer7E89AWcYvCEhtetsQ84z8l2YQ',
  p: '1kIY1HSjTgBbLQAgiJ0s8zaASXvvR-lTiOj6ka4JdDwbcxSduH1scuafbDvrDNcC7AVohvh7D18_gF1XdPgOXadHY4S7DRpRmVoYQmBfeB9yxO5sQxAExlNPKJarybttK34kQ6cP2Q1kRzFJL73HpsjQ8NihWewaZzMVQ13gi40',
  q: 'zeBJlezhvrfh2g9YRA5gWTPleSgCA7WmsvMsRXsujobY0PHfKZbjHSTx_SN3w42qxWaOQ2wInujIurj-216dj1-MvPrbXaTApxdjgW0YvGVbQxIujl0dTF_tA9UGKumhFzPACH7ib1Z25WfOCplI-vAuVMyFJWjuMiefuVGYHFk',
  dp: 'ee1tUeH6WNYtDKPI39WKEfIO-UJ0_dhS-FOU3DhNGv9lo4y04JtHju-dA6vpbAojmIrPXBeleC785SC5N1A-ZJ9gKcsL4z3596nkqklLqwFCf70qkNNZ1D7uiiuMISXuz1RecL1ucFeFnFn4yxlVt50KAjVVROXudJIGZKrUsLU',
  dq: 'Iwxc2qYyJDq_u1TDbQqZgnYgScjy1tjU4UAeuFFpMqBoRuH2YOksDukcPUENgYAbo3F7JKU-rnbovUkDMitgXWkh14ZJVB7ZIVkM-pqtDF2g9MEipd_aPgdSnUVlwGZ3ozCPL6MSP2wT36gVXp6L2xSKfqs2u5CqhGVUfsMSRzk',
  qi: 'JqanWm9afBsmSb1SuUT1bnYGfJsU2n8Z3mzMRWGvZ2RM1A3evj_J1prgCzqUD6SVv_hwl3UDPAoAVbvoZEpr1Et9HYEcqUUq9TbuklYbR3HRpaHqrsp5j2y7TeP8sW1MPG1SqJa0zqWHqEa8nTopbTixNOlNblTcT1s96YcFK0Q',
};
const publicKeySPKI =
  '-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEArE6lTi8bu9+gkmeDJafSV70Fcwv38kg7usO+mbS83mYOtpRLYbKdAk5eCC0MOk4qZNHJVrHOkS9L9IEuJUAeP2nqz/x9V5RYyTDyHQDrpcZ3SUjmgjNLCJeYE1lJiOIZ37M+U6eRb/NCGDq1ZTYjbEpvNkAFkYlgSYatBVq6zr0l50+e4py48uESThfbDSTrOo1DSo3n78H3EW4csE6zR9XuEjZsU9qLzQMBrmLNkGLRKJ3rT8VEhRcWNX1lF4qXWmIo1ftS739/wP4cOgjq5KI4tMU6evfdFif6xc07FqouOc/bg84GrdMXnZGpBskMTyk7QBf2yxCCNfgPqwvwBQIDAQAB-----END PUBLIC KEY-----';
const publicKeyJWK = {
  kty: 'RSA',
  n: 'rE6lTi8bu9-gkmeDJafSV70Fcwv38kg7usO-mbS83mYOtpRLYbKdAk5eCC0MOk4qZNHJVrHOkS9L9IEuJUAeP2nqz_x9V5RYyTDyHQDrpcZ3SUjmgjNLCJeYE1lJiOIZ37M-U6eRb_NCGDq1ZTYjbEpvNkAFkYlgSYatBVq6zr0l50-e4py48uESThfbDSTrOo1DSo3n78H3EW4csE6zR9XuEjZsU9qLzQMBrmLNkGLRKJ3rT8VEhRcWNX1lF4qXWmIo1ftS739_wP4cOgjq5KI4tMU6evfdFif6xc07FqouOc_bg84GrdMXnZGpBskMTyk7QBf2yxCCNfgPqwvwBQ',
  e: 'AQAB',
};
const privateKeyAlgorithm = 'RSA-OAEP-256';
const publicKeyAlgorithm = 'RSA-OAEP-256';
const JWTAlgorithm = 'PS256';

export {
  privateKeyJWK,
  privateKeyPKCS8,
  publicKeyJWK,
  publicKeySPKI,
  privateKeyAlgorithm,
  publicKeyAlgorithm,
  JWTAlgorithm,
};
