import { toast, Flip } from "react-toastify";

export const saveToast = () => {
  toast.success("저장 되었습니다.", {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    transition: Flip,
  });
};

export const submitToast = () => {
  toast.success("등록이 완료되었습니다.", {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    transition: Flip,
  });
};

export const deleteToast = () => {
  toast.success("삭제가 완료되었습니다.", {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    transition: Flip,
  });
};
