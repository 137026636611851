import Swal from "sweetalert2";

export const onLogout = ({
  removeCookie,
  setAuth,
  setAccessToken,
  navigate,
}) => {
  removeCookie("refreshToken");
  removeCookie("userID");
  setAuth({});
  setAccessToken(null);
  Swal.fire({
    icon: "success",
    title: "로그아웃이 완료되었습니다.",
    timer: 1500,
    showConfirmButton: false,
  });
  navigate("/");
  console.log("LOGOUT!!");
};
