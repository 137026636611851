import React, { useEffect, useMemo, useRef } from "react";
import "./styles.css";

const WheelPickerComponent = ({
  hourItems,
  hourValue,
  onHourChange: handleHourChange,
  minuteItems,
  minuteValue,
  onMinuteChange: handleMinuteChange,
  containerHeight = 130,
  itemHeight = 22,
}) => {
  const hourItemsContRef = useRef();
  const minuteItemsContRef = useRef();
  const isScrolling = useRef(false);
  const hourRefs = useRef([]);
  const minuteRefs = useRef([]);
  const hourItemsMap = useMemo(
    () =>
      hourItems.reduce(
        (map, item, index) => map.set(item.value, index),
        new Map()
      ),
    [hourItems]
  );
  const currentHourValue = useRef(hourItemsMap.get(hourValue) ?? 0);
  const minuteItemsMap = useMemo(
    () =>
      minuteItems.reduce(
        (map, item, index) => map.set(item.value, index),
        new Map()
      ),
    [minuteItems]
  );
  const currentMinuteValue = useRef(minuteItemsMap.get(minuteValue) ?? 0);

  const visibleItemsCount = Math.floor(containerHeight / itemHeight);
  const offset = Math.round((visibleItemsCount + 1) / 2) + 1;
  const maxScrollOffset = (containerHeight - itemHeight) / 2;

  function rerenderHourElements(
    selectedElement,
    scrollTop,
    firstItemIndex = Math.max(selectedElement - offset, 0),
    lastItemIndex = Math.min(selectedElement + offset, hourItems.length)
  ) {
    if (hourRefs.current) {
      hourRefs.current
        .slice(firstItemIndex, lastItemIndex)
        .forEach((item, index) => {
          const realIndex = index + firstItemIndex;
          const scrollOffset = Math.min(
            Math.abs(scrollTop - realIndex * itemHeight - itemHeight / 2),
            maxScrollOffset
          );
          const sin = scrollOffset / maxScrollOffset;
          const cos = Math.sqrt(1 - sin ** 2);
          const [div] = item.getElementsByTagName("div");
          div.style.transform = `rotateX(${Math.asin(sin)}rad) scale(${cos})`;
          div.style.transformOrigin = "right";
        });
    }
  }

  function rerenderMinuteElements(
    selectedElement,
    scrollTop,
    firstItemIndex = Math.max(selectedElement - offset, 0),
    lastItemIndex = Math.min(selectedElement + offset, minuteItems.length)
  ) {
    if (minuteRefs.current) {
      minuteRefs.current
        .slice(firstItemIndex, lastItemIndex)
        .forEach((item, index) => {
          const realIndex = index + firstItemIndex;
          const scrollOffset = Math.min(
            Math.abs(scrollTop - realIndex * itemHeight - itemHeight / 2),
            maxScrollOffset
          );
          const sin = scrollOffset / maxScrollOffset;
          const cos = Math.sqrt(1 - sin ** 2);
          const [div] = item.getElementsByTagName("div");
          div.style.transform = `rotateX(${Math.asin(sin)}rad) scale(${cos})`;
          div.style.transformOrigin = "left";
        });
    }
  }

  useEffect(() => {
    let isAnimating = false;

    function handleHourScroll(event) {
      if (!isAnimating) {
        isAnimating = true;

        requestAnimationFrame(() => {
          const scrollTop = Math.max(event.target.scrollTop, 0);
          const selectedElement = Math.min(
            Math.max(Math.floor(scrollTop / itemHeight), 0),
            hourItems.length - 1
          );
          window.clearTimeout(isScrolling.current);
          rerenderHourElements(selectedElement, scrollTop);

          currentHourValue.current = selectedElement;
          isScrolling.current = setTimeout(function () {
            handleHourChange(hourItems[selectedElement].value);
          }, 20);

          isAnimating = false;
        });
      }
    }

    function handleWheel(event) {
      event.preventDefault();
      const delta = Math.sign(event.deltaY);
      const newScrollTop =
        hourItemsContRef.current.scrollTop + delta * itemHeight;
      hourItemsContRef.current.scrollTop = newScrollTop;
    }

    const hourContainer = hourItemsContRef.current;
    hourContainer?.addEventListener("scroll", handleHourScroll);
    hourContainer?.addEventListener("wheel", handleWheel, { passive: false });

    rerenderHourElements(
      currentHourValue.current,
      hourItemsContRef.current?.scrollTop,
      0,
      hourItems.length
    );
    return () => {
      hourContainer?.removeEventListener("scroll", handleHourScroll);
      hourContainer?.removeEventListener("wheel", handleWheel);
    };
  }, [hourItemsContRef.current]);

  useEffect(() => {
    let isAnimating = false;

    function handleMinuteScroll(event) {
      if (!isAnimating) {
        isAnimating = true;

        requestAnimationFrame(() => {
          const scrollTop = Math.max(event.target.scrollTop, 0);
          const selectedElement = Math.min(
            Math.max(Math.floor(scrollTop / itemHeight), 0),
            minuteItems.length - 1
          );
          window.clearTimeout(isScrolling.current);
          rerenderMinuteElements(selectedElement, scrollTop);

          currentMinuteValue.current = selectedElement;
          isScrolling.current = setTimeout(function () {
            handleMinuteChange(minuteItems[selectedElement].value);
          }, 20);

          isAnimating = false;
        });
      }
    }

    function handleWheel(event) {
      event.preventDefault();
      const delta = Math.sign(event.deltaY);
      const newScrollTop =
        minuteItemsContRef.current.scrollTop + delta * itemHeight;
      minuteItemsContRef.current.scrollTop = newScrollTop;
    }

    const minuteContainer = minuteItemsContRef.current;
    minuteContainer?.addEventListener("scroll", handleMinuteScroll);
    minuteContainer?.addEventListener("wheel", handleWheel, { passive: false });

    rerenderMinuteElements(
      currentMinuteValue.current,
      minuteItemsContRef.current?.scrollTop,
      0,
      minuteRefs.length
    );
    return () => {
      minuteContainer?.removeEventListener("scroll", handleMinuteScroll);
      minuteContainer?.removeEventListener("wheel", handleWheel);
    };
  }, [minuteItemsContRef.current]);

  // useEffect(() => {
  //   const index = hourItemsMap.get(hourValue);
  //   if (index !== currentHourValue.current) {
  //     currentHourValue.current = index;
  //     hourRefs.current[index]?.scrollIntoView({
  //       block: "center",
  //       behavior: "smooth",
  //     });
  //   }
  // }, [hourValue]);

  // useEffect(() => {
  //   const index = minuteItemsMap.get(minuteValue);
  //   if (index !== currentMinuteValue.current) {
  //     currentMinuteValue.current = index;
  //     minuteRefs.current[index]?.scrollIntoView({
  //       block: "center",
  //       behavior: "smooth",
  //     });
  //   }
  // }, [minuteValue]);

  return (
    <div
      className="container"
      style={{
        height: `${containerHeight}px`,
      }}
    >
      <ul className="items" ref={hourItemsContRef}>
        {hourItems.map((item, index) => (
          <li
            className="item"
            key={item.value}
            ref={(node) => (hourRefs.current[index] = node)}
            style={{
              height: `${itemHeight}px`,
              lineHeight: `${itemHeight}px`,
            }}
          >
            <div>{item.label}</div>
          </li>
        ))}
      </ul>
      <div className="flexCenterAll pb-6">:</div>
      <ul className="items" ref={minuteItemsContRef}>
        {minuteItems.map((item, index) => (
          <li
            className="item"
            key={item.value}
            ref={(node) => (minuteRefs.current[index] = node)}
            style={{
              height: `${itemHeight}px`,
              lineHeight: `${itemHeight}px`,
            }}
          >
            <div>{item.label}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const WheelPicker = React.memo(WheelPickerComponent);
