export const ReactSelectStyles = ({ type, width = "auto" }) => {
  switch (type) {
    case "number":
      return {
        container: (prevState) => ({
          ...prevState,
          width: width,
        }),
        control: (prevState) => ({
          ...prevState,
          backgroundColor: "var(--theme-item-1-color)",
          color: "var(--theme-font-color)",
          borderColor: "var(--theme-border-2-color)",
        }),
        input: (prevState) => ({
          ...prevState,
          color: "var(--theme-font-color)",
        }),
        singleValue: (prevState) => ({
          ...prevState,
          color: "var(--theme-font-color)",
        }),
        menu: (prevState) => ({
          ...prevState,
          backgroundColor: "var(--theme-item-1-color)",
          color: "var(--theme-font-color)",
        }),
        option: (prevState) => ({
          ...prevState,
          backgroundColor: "var(--theme-item-1-color)",
          color: "var(--theme-font-color)",
        }),
      };
    case "category":
      return {
        container: (prevState) => ({
          ...prevState,
          width: width,
        }),
        control: (prevState) => ({
          ...prevState,
          borderRadius: "0.5rem",
        }),
        menu: (prevState) => ({
          ...prevState,
          borderRadius: "0.5rem",
        }),
        option: (prevState) => ({
          ...prevState,
          color: "black",
        }),
      };
    default:
      return {};
  }
};
