import React, { useEffect, useState } from "react";
import { Navigation } from "./Navigation";
import "@theme-toggles/react/css/Classic.css";
import { Classic } from "@theme-toggles/react";
import GNGLogo_clear from "assets/images/GNG_logo_2_clear.png";
import GNGLogo_color from "assets/images/GNG_logo_2_color.png";
import "assets/styles/HeaderStyle.css";
import { Link, useNavigate } from "react-router-dom";
import { authState } from "components/common/Zustand";
import { useCookies } from "react-cookie";
import { onLogout } from "functions/auth/onLogout";

// Helper function to load state from localStorage
const loadState = () => {
  const state = localStorage.getItem("themeState");
  if (state) {
    return JSON.parse(state);
  } else {
    return { theme: "dark" };
  }
};

// Helper function to save state to localStorage
const saveState = (state) => {
  localStorage.setItem("themeState", JSON.stringify(state));
};

export const Header = () => {
  const [theme, setTheme] = useState(loadState().theme);
  const { auth, setAuth, setAccessToken } = authState();
  const [cookies, setCookie, removeCookie] = useCookies([
    "refreshToken",
    "userID",
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    const root = document.documentElement;
    if (theme === "light") {
      root.style.setProperty("--theme-background-color", "hsl(0,0%,98%)");
      root.style.setProperty("--theme-item-1-color", "hsl(0,0%,100%)");
      root.style.setProperty(
        "--theme-item-1-blur-color",
        "hsla(0,0%,100%,0.8)"
      );
      root.style.setProperty("--theme-item-2-color", "hsl(0,0%,95%)");
      root.style.setProperty("--theme-item-2-hover-color", "hsl(0,0%,85%)");
      root.style.setProperty("--theme-border-1-color", "hsl(0, 0%, 80%)");
      root.style.setProperty("--theme-border-2-color", "hsl(0, 0%, 70%)");
      root.style.setProperty("--theme-font-color", "black");
      root.style.setProperty("--theme-toggle-button-color", "orange");
    } else if (theme === "dark") {
      root.style.setProperty("--theme-background-color", "hsl(240, 5%, 15%)");
      root.style.setProperty("--theme-item-1-color", "hsl(240, 5%, 17%)");
      root.style.setProperty(
        "--theme-item-1-blur-color",
        "hsla(240, 5%, 17%,0.8)"
      );
      root.style.setProperty("--theme-item-2-color", "hsl(240, 5%, 22%)");
      root.style.setProperty("--theme-item-2-hover-color", "hsl(240, 5%, 29%)");
      root.style.setProperty("--theme-border-1-color", "hsl(0, 0%, 20%)");
      root.style.setProperty("--theme-border-2-color", "hsl(0, 0%, 25%)");
      root.style.setProperty("--theme-font-color", "white");
      root.style.setProperty("--theme-toggle-button-color", "yellow");
    }
    saveState({ theme }); // Save theme to localStorage whenever it changes
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  return (
    <div id="Header" className="themeItem1BlurColor themeBorder1Color">
      <Link to={"/"}>
        <div id="HeaderLogoArea">
          <img
            src={theme === "light" ? GNGLogo_color : GNGLogo_clear}
            alt="GNG Logo"
          />
          <div id="HeaderLogoDescription" className="themeFontColor">
            : 제인의 보스 투데이
          </div>
        </div>
      </Link>
      <div id="HeaderNavigationArea">
        <Navigation />
      </div>
      <div id="HeaderOptionArea">
        <div id="LoginBox" className="themeFontColor">
          {Object.keys(auth).length > 0 ? (
            <div
              id="LogoutButton"
              onClick={() =>
                onLogout({ removeCookie, setAuth, setAccessToken, navigate })
              }
            >
              <div>{auth?.userName}</div>
              <div>로그아웃</div>
            </div>
          ) : (
            <Link to="/login">
              <div id="LoginButton">로그인</div>
            </Link>
          )}
        </div>
        <div id="ThemeToggleBox">
          <Classic
            toggled={theme === "dark"}
            toggle={toggleTheme}
            duration={750}
            className="themeToggleButtonColor"
          />
        </div>
      </div>
    </div>
  );
};
