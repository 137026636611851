export const Footer = () => {
  return (
    <div id="Footer" className="flexCenterAll pageDivider">
      <div id="CopyRight" className="flexCenterAll fontSize12px fontColorGrey">
        Copyright 2024 @그라나도에스파다M펠리베서버유저 ( 한울당 오늘날씨 /
        경로당 소피더다이아 )
      </div>
    </div>
  );
};
