import "assets/styles/SummonCharmStyle.css";
import 전날소환부적 from "assets/images/전날 소환부적.png";
import 전펫소환부적 from "assets/images/전펫 소환부적.png";

export const SummonCharm = () => {
  return (
    <div id="SummonCharm" className="page themeFontColor">
      <div className="sectionSubTitle">소환 부적</div>
      <div className="flexCenterAll flexDirectionColumn mt-2">
        <div className="summonCharmItemName">전날 소환부적</div>
        <img id="CharmImage1" src={전날소환부적} />
        <div className="fontSize12px mt-1">made by 한울당 봉인된 핫빠리</div>
      </div>
      <div className="flexCenterAll flexDirectionColumn mt-4">
        <div className="summonCharmItemName">전펫 소환부적</div>
        <img id="CharmImage1" src={전펫소환부적} />
        <div className="fontSize12px mt-1">made by 한울당 봉인된 핫빠리</div>
      </div>
    </div>
  );
};
