import API from "functions/API";
import { useEffect, useState } from "react";
import { normalize, schema } from "normalizr";
import "assets/styles/TacticArticlesStyle.css";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { authState } from "components/common/Zustand";
import { TacticArticleWrite } from "./TacticArticleWrite";
import TacticArticleView from "./TacticArticleView";
import dayjs from "dayjs";
import { TacticArticleRevise } from "./TacticsArticleRevise";
import { FaEye } from "react-icons/fa";

export const TacticArticles = () => {
  const { auth } = authState();
  const [articlesData, setArticlesData] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const getArticles = async () => {
      const getArticlesResult = await API.get(`api/v1/tacticArticles`);
      // console.log(getArticlesResult);

      // 데이터 스키마 정의
      const dataSchema = new schema.Entity("data", {}, { idAttribute: "idx" });

      // 카테고리별 데이터 그룹화 함수
      const groupByCategory = (data) => {
        return data.reduce((acc, item) => {
          const { category, ...rest } = item;
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(rest);
          return acc;
        }, {});
      };

      // 데이터를 카테고리 기준으로 그룹화
      const groupedData = groupByCategory(getArticlesResult.data);

      // 정규화된 데이터를 담을 객체
      const normalizedData = {};

      // 각 카테고리별로 데이터를 정규화
      Object.keys(groupedData).forEach((category) => {
        normalizedData[category] = normalize(groupedData[category], [
          dataSchema,
        ]);
      });

      setArticlesData(normalizedData);
      // console.log("정규화된 데이터:", normalizedData);
      setCategoryOptions(
        Object.keys(normalizedData).map((categoryName, i) => {
          return {
            idx: i,
            key: i,
            label: categoryName,
            data: categoryName,
          };
        })
      );
    };
    if (location.pathname === "/TacticArticles") getArticles();
  }, [location.pathname]);

  const Category = ({ categoryName, articles }) => {
    const Article = ({ article }) => {
      // console.log("article : ", article);
      return (
        <Link to={`/TacticArticles/${article.idx}`}>
          <div id="Article">
            <div id="Thumbnail" className="themeItem2Color themeBorder2Color">
              {article.thumbnailUrl ? (
                <img src={article.thumbnailUrl ?? null} />
              ) : (
                <div id="NoImage">이미지 없음</div>
              )}
            </div>
            <div id="DescriptionArea" className="mt-half">
              <div id="Subject">{article.subject}</div>
              <div id="Writer">written by {article.Writer.userName}</div>
              <div id="CreatedAt">
                {dayjs(article?.createdAt).format("YYYY.MM.DD")}
              </div>
              <div id="ReadCount">
                <div className="flexCenterAll">
                  <FaEye />
                </div>
                <div className="ml-half">{article?.readCount}</div>
              </div>
            </div>
          </div>
        </Link>
      );
    };

    return (
      <div id="Category">
        <span className="sectionSubTitle">{categoryName}</span>
        <div
          id="Articles"
          className="sectionItem themeItem1Color themeBorder1Color"
        >
          {Object.entries(articles ?? {}).map((articleItemEntity) => {
            // console.log(articleItemEntity);
            return (
              <Article
                key={articleItemEntity[0]}
                article={articleItemEntity[1]}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const Write = () => {
    return (
      <div id="WriteButtonArea" className="sectionSubTitle">
        글쓰기 (관리자용)
        <div className="sectionItem themeItem1Color themeBorder1Color">
          <Link to="Write">
            <button>글쓰기</button>
          </Link>
        </div>
      </div>
    );
  };

  const View = () => {
    const categories = Object.entries(articlesData).map((categoryEntity) => {
      let categoryName = categoryEntity[0];
      let articles = categoryEntity[1]?.entities.data;
      return (
        <Category
          key={categoryName}
          categoryName={categoryName}
          articles={articles}
        />
      );
    });
    switch (auth?.userAuth) {
      default:
        return categories;
      case "admin":
        return (
          <div>
            <Write />
            {categories}
          </div>
        );
    }
  };

  return (
    <div id="TacticArticles" className="page themeFontColor">
      <Routes>
        <Route index element={<View />} />
        <Route
          path=":articleID"
          element={<TacticArticleView categoryOptions={categoryOptions} />}
        />
        <Route
          path=":articleID/revise"
          element={<TacticArticleRevise categoryOptions={categoryOptions} />}
        />
        <Route
          path="Write"
          element={
            <TacticArticleWrite
              categoryOptions={categoryOptions}
              isPageValid={true}
            />
          }
        />
      </Routes>
    </div>
  );
};
