import "assets/styles/CollectionStyle.css";
import { Link, Route, Routes } from "react-router-dom";
import heroCollectionImg from "assets/images/img_hero_collection.png";
import itemCollectionImg from "assets/images/img_item_collection.png";
import monsterCollectionImg from "assets/images/img_monster_collection.png";
import { HeroCollection } from "./HeroCollection";
import applicationData from "data/applicationData.json";

const ApplicationSellector = () => {
  return (
    <div id="CollectionSellector">
      <div>
        <span className="sectionSubTitle">어플리케이션</span>
      </div>
      <div className="sectionItem themeItem1Color themeBorder1Color">
        <div id="Applications">
          {(applicationData ?? []).map((applicationItem) => {
            return (
              <div
                id="Application"
                style={{ opacity: applicationItem.isValid ? 1 : 0.3 }}
              >
                <Link to={applicationItem.isValid ? applicationItem.link : "#"}>
                  <div
                    id="Thumbnail"
                    className="themeItem2Color themeBorder2Color"
                  >
                    {applicationItem.thumbnailUrl ? (
                      <img src={applicationItem.thumbnailUrl ?? null} />
                    ) : (
                      <div id="NoImage">이미지 없음</div>
                    )}
                  </div>
                  <div id="DescriptionArea" className="mt-half">
                    <span className="sectionSubTitle">
                      {applicationItem.title}
                    </span>
                  </div>
                </Link>
              </div>
            );
          })}
          {/* <div id="HeroCollectionImg">
          <Link to={"/Collection/HeroCollection"}>
            <img src={heroCollectionImg} />
          </Link>
        </div>
        <div id="MonsterCollectionImg">
          <img src={monsterCollectionImg} />
        </div> */}
        </div>
      </div>
    </div>
  );
};

export const Applications = () => {
  return (
    <div id="Collection" className="page themeFontColor">
      <div className="pageItem">
        <Routes>
          <Route index element={<ApplicationSellector />} />
          <Route path="HeroCollection" element={<HeroCollection />} />
        </Routes>
      </div>
    </div>
  );
};
