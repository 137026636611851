export const JWT_SECRET_KEY = "weather";
export const API_KEY = "weather";
// export const HOST_URL = "http://localhost:3000";
// export const API_URL = "http://localhost:8000";
export const HOST_URL = "https://www.grahagra.site:3000";
export const API_URL = "https://www.grahagra.site:8000";

// AWS
export const AWS_URL = "https://jane-today.s3.ap-northeast-2.amazonaws.com";
export const AWS_S3_BUCKET = "jane-today";
export const AWS_S3_REGION = "ap-northeast-2";
export const AWS_ACCESS_KEY = "AKIASPXOLT3CDMBIPU6R";
export const AWS_SECRET_KEY = "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";
